import React, { useState, useEffect } from 'react';
import { Box, Skeleton, Text, Avatar } from '@chakra-ui/react';
import FAIcon from '../../../components/FAIcon';
import ILinkedUser, {
  IIncompleteLinkedUser,
  IRejectedLinkedUser,
  ISupporterLinkedUser,
} from '../../linked-users/interfaces/ILinkedUser';
import { isIncompleteLinkedUser, isRejectedLinkedUser, isSupporterLinkedUser } from '../../linked-users/utils';
import IUser from '../../user/interfaces/IUser';
import { getByRef as getUserByRef } from '../../user/utils';

interface Props {
  linkedUser: IIncompleteLinkedUser | ILinkedUser | ISupporterLinkedUser | IRejectedLinkedUser;
}

const NetworkMember = ({ linkedUser }: Props): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<null | IUser>(null);

  useEffect(() => {
    let isMounted = true;

    if (isIncompleteLinkedUser(linkedUser) || isSupporterLinkedUser(linkedUser) || isRejectedLinkedUser(linkedUser)) {
      if (isMounted) setLoading(false);
    } else {
      setLoading(true);
      getUserByRef(linkedUser.otherUser).then((v) => {
        if (isMounted) {
          setUser(v);
          setLoading(false);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [linkedUser]);

  const DefaultIcon = () => <FAIcon icon="user-circle" as="div" fontSize="87px" lineHeight="1em" color="grey3.400" />;

  let nameDisplay: string;
  if (linkedUser.contactAlias) {
    nameDisplay = linkedUser.contactAlias;
  } else if (isIncompleteLinkedUser(linkedUser) && (linkedUser.name || linkedUser.email)) {
    nameDisplay = linkedUser.name || linkedUser.email || '???';
  } else if (user) {
    nameDisplay = user.name ?? user.email;
  } else {
    nameDisplay = 'Unknown';
  }

  return (
    <Box textAlign="center" w="87px" mb={4}>
      <Avatar
        minW="87px"
        minH="87px"
        src={user?.image}
        backgroundColor="white"
        icon={<DefaultIcon />}
        // name={user.name} << enable for name initials as fallback
      />

      <Skeleton isLoaded={!loading}>
        <Text
          fontStyle={isIncompleteLinkedUser(linkedUser) ? 'italic' : undefined}
          mt="8px"
          color="#8F8F8F"
          overflow="hidden"
          fontWeight={500}
          fontSize="16px"
          lineHeight="18.8px"
          style={{
            textOverflow: 'ellipsis',
          }}
        >
          {nameDisplay}
        </Text>
      </Skeleton>
    </Box>
  );
};

export default NetworkMember;
