import { Action } from 'redux';
import firebase from 'firebase/compat/app';
import AppThunk from '../../redux/interfaces/AppThunk';
import Firestore, { FirestoreUtils } from '../firestore/Firestore';
import IDeviceGarmin from './interfaces/IDeviceGarmin';
import { formatDeviceGarminFromFirestore, formatDeviceGarminToFirestore } from './utils';

export enum DeviceGarminActionTypes {
  SET_DEVICE_GARMIN = 'SET_DEVICE_GARMIN',
  GETTING_DEVICE_GARMIN = 'GETTING_DEVICE_GARMIN',
  GOT_DEVICE_GARMIN = 'GOT_DEVICE_GARMIN',
  SET_DEVICE_GARMIN_UNSUB = 'SET_DEVICE_GARMIN_UNSUB',
  CREATED_DEVICE_GARMIN = 'CREATED_DEVICE_GARMIN',
  CREATING_DEVICE_GARMIN = 'CREATING_DEVICE_GARMIN',
  DELETING_DEVICE_GARMIN = 'DELETING_DEVICE_GARMIN',
  DELETED_DEVICE_GARMIN = 'DELETED_DEVICE_GARMIN',
  UPDATING_DEVICE_GARMIN = 'UPDATING_DEVICE_GARMIN',
  UPDATED_DEVICE_GARMIN = 'UPDATED_DEVICE_GARMIN',
}

export type DeviceGarminActions =
  | SetDeviceGarminAction
  | GettingDeviceGarminAction
  | GotDeviceGarminAction
  | SetDeviceGarminUnsubAction
  | CreatedDeviceGarminAction
  | CreatingDeviceGarminAction
  | UpdatingDeviceGarminAction
  | UpdatedDeviceGarminAction;

// | DeletingDeviceGarminAction
// | DeletedDeviceGarminAction

export interface SetDeviceGarminAction extends Action<DeviceGarminActionTypes.SET_DEVICE_GARMIN> {
  deviceGarmin: null | IDeviceGarmin;
}

export const setDeviceGarmin = (deviceGarmin: null | IDeviceGarmin): SetDeviceGarminAction => ({
  type: DeviceGarminActionTypes.SET_DEVICE_GARMIN,
  deviceGarmin,
});

export type GettingDeviceGarminAction = Action<DeviceGarminActionTypes.GETTING_DEVICE_GARMIN>;

export const gettingDeviceGarmin = (): GettingDeviceGarminAction => ({
  type: DeviceGarminActionTypes.GETTING_DEVICE_GARMIN,
});

export interface GotDeviceGarminAction extends Action<DeviceGarminActionTypes.GOT_DEVICE_GARMIN> {
  deviceGarmin: IDeviceGarmin;
}

export const gotDeviceGarmin = (deviceGarmin: IDeviceGarmin): GotDeviceGarminAction => {
  return {
    type: DeviceGarminActionTypes.GOT_DEVICE_GARMIN,
    deviceGarmin,
  };
};

export const getDeviceGarmin = (pirId: string): AppThunk<Promise<GotDeviceGarminAction>, DeviceGarminActions> => {
  return async (dispatch) => {
    dispatch(gettingDeviceGarmin());

    return new Promise<IDeviceGarmin>((resolve, reject) => {
      const unsubFn = Firestore.collection('deviceAuthGarminUserId')
        .where('pir', '==', FirestoreUtils.getDocRef('users', pirId))
        .where('status', '==', 'AUTHORIZED')
        .onSnapshot(
          (snapshot) => {
            const deviceGarmin = snapshot.docs.map((snapshot) => formatDeviceGarminFromFirestore(snapshot))[0];
            dispatch(setDeviceGarmin(deviceGarmin));
            resolve(deviceGarmin);
          },
          (error) => {
            reject(error);
          },
        );
      dispatch(setDeviceGarminUnsub(unsubFn));
    }).then((deviceGarmin) => {
      return dispatch(gotDeviceGarmin(deviceGarmin));
    });
  };
};

export interface SetDeviceGarminUnsubAction extends Action<DeviceGarminActionTypes.SET_DEVICE_GARMIN_UNSUB> {
  unsubFn: null | firebase.Unsubscribe;
}

export const setDeviceGarminUnsub = (unsubFn: null | firebase.Unsubscribe): SetDeviceGarminUnsubAction => ({
  type: DeviceGarminActionTypes.SET_DEVICE_GARMIN_UNSUB,
  unsubFn,
});

export const stopDeviceGarminListener = (): AppThunk<SetDeviceGarminUnsubAction, SetDeviceGarminUnsubAction> => {
  return (dispatch, getState) => {
    const unsubFn = getState().deviceGarmin.unsubFn;
    if (unsubFn) {
      unsubFn();
    }
    return dispatch(setDeviceGarminUnsub(null));
  };
};

export type CreatingDeviceGarminAction = Action<DeviceGarminActionTypes.CREATING_DEVICE_GARMIN>;

export const creatingDeviceGarmin = (): CreatingDeviceGarminAction => ({
  type: DeviceGarminActionTypes.CREATING_DEVICE_GARMIN,
});

export interface CreatedDeviceGarminAction extends Action<DeviceGarminActionTypes.CREATED_DEVICE_GARMIN> {
  deviceGarmin: IDeviceGarmin;
}

export const createdDeviceGarmin = (deviceGarmin: IDeviceGarmin): CreatedDeviceGarminAction => ({
  type: DeviceGarminActionTypes.CREATED_DEVICE_GARMIN,
  deviceGarmin,
});

export const createDeviceGarmin = (
  rawDeviceGarmin: Omit<IDeviceGarmin, 'id'>,
): AppThunk<Promise<CreatedDeviceGarminAction>, DeviceGarminActions> => {
  return async (dispatch) => {
    dispatch(creatingDeviceGarmin());

    const deviceGarmin = formatDeviceGarminToFirestore(rawDeviceGarmin);
    const deviceGarminRef = Firestore.collection('deviceAuthGarminUserId').doc();
    await deviceGarminRef.set(deviceGarmin);
    return dispatch(
      createdDeviceGarmin({
        ...deviceGarmin,
        id: deviceGarminRef.id,
      }),
    );
  };
};

// export type DeletingDeviceGarminAction = Action<DeviceGarminActionTypes.DELETING_DEVICE_GARMIN>;
// export type DeletedDeviceGarminAction = Action<DeviceGarminActionTypes.DELETED_DEVICE_GARMIN>;

// export const deleteDeviceGarmin = (
//   deviceGarmins: IDeviceGarmin
// ): AppThunk<Promise<DeletedDeviceGarminAction>, DeviceGarminActions> => {
//   return async (dispatch) => {
//     dispatch({ type: DeviceGarminActionTypes.DELETING_DEVICE_GARMIN });
//     await Firestore.collection('deviceGarmins').doc(deviceGarmins.id).delete();
//     return dispatch({ type: DeviceGarminActionTypes.DELETED_DEVICE_GARMIN });
//   };
// };

export type UpdatingDeviceGarminAction = Action<DeviceGarminActionTypes.UPDATING_DEVICE_GARMIN>;

export interface UpdatedDeviceGarminAction extends Action<DeviceGarminActionTypes.UPDATED_DEVICE_GARMIN> {
  deviceGarmin: IDeviceGarmin;
}

export const updateDeviceGarmin = (
  deviceGarmin: IDeviceGarmin,
): AppThunk<Promise<UpdatedDeviceGarminAction>, DeviceGarminActions> => {
  return async (dispatch) => {
    dispatch({ type: DeviceGarminActionTypes.UPDATING_DEVICE_GARMIN });
    await Firestore.collection('deviceAuthGarminUserId').doc(deviceGarmin.id).set(deviceGarmin);
    return dispatch({
      type: DeviceGarminActionTypes.UPDATED_DEVICE_GARMIN,
      deviceGarmin,
    });
  };
};

export const deregisterGarminAPI = async (garminDeviceAPI: IDeviceGarmin) => {
  if (garminDeviceAPI?.pir !== undefined) {
    const deviceGarminUpdate: IDeviceGarmin = {
      ...garminDeviceAPI,
      ...{ status: 'TO_REVOKE' },
    };

    await updateDeviceGarmin(deviceGarminUpdate);
  }
};
