import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logout } from '../auth/actions';
import { wearableForget } from '../../lib/wearable';
import { logoutGarmin } from '../../redux/actions/garmin';

const LogOut = (props: PropsFromRedux): JSX.Element => {
  useEffect(() => {
    if (window.cordova) {
      wearableForget();
    }
    logoutGarmin();
    props.logout();
  }, []);

  return <Redirect to="/" />;
};

const connector = connect(null, {
  logout,
});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LogOut);
