import React, { useState, useEffect, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  HStack,
} from '@chakra-ui/react';

import MedicationModal from '../../components/ManageMedicationModal/MedicationModal';
import { RootState } from '../../redux/store';
import IMedication from './interfaces/IMedication';
import MedCal from '../../components/ManageMedicationModal/MedCal';
import { createInteraction } from '../interactions/actions';
import { moduleName, interactionType } from '../interactions/constants';
import { DateUtils } from 'react-day-picker';
import { isPirLinkedUser } from '../linked-users/utils';
import ModuleCard from '../../components/ModuleCard';
import { updateToDo } from '../../modules/todo/actions';
import ModuleContainer from '../../components/ModuleContainerV2';
import { useQueryMedications } from './queryHooks';
import { formatFrequency } from './utils';
import { useModal } from 'src/ModalContext';
import { useDevice } from 'src/DeviceContext';

const MODAL_NAME = 'medications';

const MedicationModule = ({ pir, createInteraction, linkedUser }: PropsFromRedux): React.ReactElement => {
  const { t } = useTranslation('medications');
  const [selectedMedication, setSelectedMedication] = useState<IMedication | undefined>(undefined);
  const todayDate = new Date();
  const { isPhone } = useDevice();
  const { modal, openModal, closeModal } = useModal();

  const { data: medications = [] } = useQueryMedications(pir?.id);

  // Using actions.ts as template for how to check if is PIR
  let isPir = false;

  if (linkedUser) {
    isPir = isPirLinkedUser(linkedUser);
  }

  const history = useHistory();

  const addClosedInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MEDICATIONS,
      interactionType: interactionType.MEDICATIONS.CLOSED,
    });
  };

  const addViewedInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.MEDICATIONS,
      interactionType: interactionType.MEDICATIONS.VIEWED,
    });
  };

  const hasDoneToday = (medication: IMedication) => {
    if (medication.goalDaysTracked)
      return medication.goalDaysTracked.filter((date) => DateUtils.isSameDay(date, todayDate)).length > 0;
    return false;
  };

  const toDisplayMedication = useCallback((medication: IMedication) => {
    setSelectedMedication(medication);
  }, []);

  const toDisplayCal = () => {
    if (selectedMedication) {
      return (
        <Flex direction="column" alignItems="center">
          <MedCal
            medication={selectedMedication}
            dayColorTracker={selectedMedication?.goalDaysTracked}
            hasDoneToday={hasDoneToday(selectedMedication)}
            todayDate={todayDate}
            isUserPir={isPir}
            completedDays={selectedMedication?.completedDays ? selectedMedication?.completedDays : []}
          />
        </Flex>
      );
    }
  };

  const formatGoalDisplay = () => {
    if (selectedMedication?.startDate) {
      if (selectedMedication.startDate > new Date()) {
        return `${t('medicationModule.goalStarts')} ${selectedMedication.startDate.toLocaleDateString()}`;
      } else if (
        selectedMedication.streak &&
        Number(selectedMedication.completedDays?.length) < selectedMedication.streak
      ) {
        return `${t('medicationModule.goalStarted')} ${selectedMedication.startDate.toLocaleDateString()}`;
      } else if (
        selectedMedication.streak &&
        Number(selectedMedication.completedDays?.length) > selectedMedication.streak
      ) {
        return t('medicationModule.goalStarted');
      }
    }
  };

  const formatFrequencyDisplay = (selectedMedication?: IMedication) => {
    if (selectedMedication) return formatFrequency(selectedMedication);
    else return '';
  };

  useEffect(() => {
    if (modal === MODAL_NAME) {
      createInteraction({
        pir,
        dateTime: new Date(),
        moduleName: moduleName.MEDICATIONS,
        interactionType: interactionType.MEDICATIONS.OPEN_MODAL,
      });
    }
  }, [modal === MODAL_NAME]);

  useEffect(() => {
    const url = window.location.href;
    const medicationRoute = url.substring(url.lastIndexOf('/') + 1).split("?")[0]; // get last path from url

    // check if viewing specific resource
    if (medicationRoute !== MODAL_NAME && medicationRoute !== 'home') {
      // viewing specific medication, route has resource id
      // find which medication to display based on medication id in route
      for (let i = 0; i < medications.length; i++) {
        if (medications[i].id === medicationRoute) setSelectedMedication(medications[i]);
      }
    } else {
      // not viewing specific medication
      setSelectedMedication(undefined);
    }
  }, [window.location.href]);

  return (
    <Box>
      <ModuleContainer
        title={t('medicationModule.moduleHeader')}
        icon="capsules"
        isEmptyModule={medications.length <= 0}
        modalName={MODAL_NAME}
        moduleInstruct={isPir ? [t('medicationModule.moduleInstructPIR')] : [t('medicationModule.moduleInstruct')]}
        moduleButton={t('medicationModule.moduleButton')}
        cogFunction={() => openModal(MODAL_NAME)}
      >
        <HStack
          width="100%"
          spacing={['8px', '30px']}
          overflowX="auto"
          alignItems="stretch"
          justifyContent="flex-start"
        >
          {medications.map((medication) => (
            <ModuleCard
              moduleInterface={medication}
              modalName={MODAL_NAME}
              description={medication.frequency}
              toDisplayFunction={toDisplayMedication}
              name={medication.name}
              icon="capsules"
              bgColor="#F0D1CE"
              textColor="#C98881"
              deleteOpen={false}
              width={['108px', '192px']}
              key={medication.id}
            />
          ))}
        </HStack>
      </ModuleContainer>

      <Modal
        isOpen={selectedMedication !== undefined}
        onClose={() => {
          history.push('/home');
          setSelectedMedication(undefined);
          closeModal();
          addViewedInteraction();
        }}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '98%', '90%', '85%', '75%']}
          p={[2, 2, 4, 8]}
          zIndex={1900}
        >
          <ModalHeader>{selectedMedication?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody w="100%">
            <Flex w="100%" direction="row" justifyContent="space-between">
              <Text color="grey3.400" fontSize={16} mb={3}>
                {formatGoalDisplay()}
              </Text>
              <Text color="grey3.400" fontSize={16} mb={3}>
                {' '}
                Frequency: {formatFrequencyDisplay(selectedMedication)}
              </Text>
            </Flex>
            {toDisplayCal()}
          </ModalBody>
        </ModalContent>
      </Modal>
      <MedicationModal
        isOpen={modal === MODAL_NAME && !selectedMedication}
        onOpen={() => openModal(MODAL_NAME)}
        onClose={() => {
          closeModal();
          if (isPir) addClosedInteraction();
        }}
      />
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  const { selectedLinkedUser } = state.linkedUsers;

  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }

  return {
    pir: selectedLinkedUser.pir,
    linkedUser: selectedLinkedUser,
  };
};

const mapDispatchToProps = {
  updateToDo,
  createInteraction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MedicationModule);
