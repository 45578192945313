import React, { useEffect, useState, useRef, useContext } from 'react';
import { Controller, useForm, ControllerRenderProps } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  Text,
  Flex,
  useDisclosure,
  Select,
  FormErrorMessage,
  Input,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import { createInteraction } from '../../modules/interactions/actions';
import { moduleName, interactionType } from '../../modules/interactions/constants';
import FAIcon from '../FAIcon';
import ReactDatePicker from 'react-datepicker';
import './DatePicker.css';
import ILinkedUser from '../../modules/linked-users/interfaces/ILinkedUser';
import { getByRef as getUserByRef, wasEdited } from '../../modules/user/utils';

import { Center, Stack } from '@chakra-ui/layout';
import IAppointment, { AppointmentStatus, LocationType } from 'src/modules/appointments/interfaces/IAppointment';
import { FirestoreUtils } from 'src/modules/firestore/Firestore';
import { useCreateAppointment, useUpdateAppointment } from 'src/modules/appointments/queryHooks';
import PrimaryButton from '../PrimaryButton';
import TertiaryButton from '../TertiaryButton';
import { UserContext } from 'src/modules/interior/Index';
import { radioCheckedStyle } from 'src/constants/theme';
import {
  defaultTimeZone,
  formatTimeStringFromDateTimeAndTz,
  getAppointmentDateStringWithDay,
  getAppointmentTimeStringRange,
  getAppointmentTimeStringWithDuration,
  getMaxTime,
  getOverlappingAppointments,
  getTz,
  isDateToday,
} from './utils';
import { UserRole } from 'src/modules/user/interfaces/IUser';
import OnCreateAppointmentModal from './OnCreateAppointmentModal';
import OnUpdateAppointmentModal from './OnUpdateAppintmentModal';

import { zonedTimeToUtc } from 'date-fns-tz';
import ConfirmOverlappingAppointmentsModal from './ConfirmOverlappingAppointmentsModal';
import { addMinutes, setHours, setMinutes } from 'date-fns';
import { useModal } from 'src/ModalContext';
import { useDevice } from 'src/DeviceContext';

export interface FormValues {
  appointmentDate: Date;
  appointmentTime: {
    timeStart: Date;
    timeEnd: Date;
    timeStartString: string;
    timeEndString: string;
  };
  providerUserId: string;
  approvedByPir?: boolean;
  approvedByProvider?: boolean;
  notesFromProvider?: string;
  notesFromPir?: string;
  type?: string;
  location?: string;
  locationType?: LocationType;
}

interface SuppliedProps {
  selectedAppointment: IAppointment | null;
  setSelectedAppointment: React.Dispatch<React.SetStateAction<IAppointment | null>>;
  isOpen: boolean;
  onClose(): void;
  providers: ILinkedUser[];
  isPir: boolean;
  existingAppointments: IAppointment[];
}

const optionHeader: Intl.DateTimeFormatOptions = { weekday: 'long', month: 'long', day: 'numeric' };

const AddAppointmentForm = ({
  selectedAppointment,
  createInteraction,
  setSelectedAppointment,
  pir,
  providers,
  isPir,
  selectedLinkedUser,
  onClose,
  isOpen,
  existingAppointments,
}: SuppliedProps & PropsFromRedux): JSX.Element => {
  const { t } = useTranslation('appointments');
  const addAppointmentDisclosure = useDisclosure();
  const { isPhone } = useDevice();
  // const { onOpen } = useDisclosure();
  const { reset, handleSubmit, formState, control, register, getValues, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      appointmentDate: selectedAppointment?.appointmentDate,
      appointmentTime: {
        timeStart: selectedAppointment?.appointmentTime.timeStart,
        timeEnd: selectedAppointment?.appointmentTime.timeEnd,
      },
      providerUserId: selectedAppointment?.providerUser?.id,
      approvedByPir: selectedAppointment?.approvedByPir,
      approvedByProvider: selectedAppointment?.approvedByProvider,
      notesFromProvider: selectedAppointment?.notesFromProvider ?? '',
      notesFromPir: selectedAppointment?.notesFromPir ?? '',
      type: selectedAppointment?.type ?? '',
      location: selectedAppointment?.location ?? '',
      locationType: selectedAppointment?.locationType,
    },
  });
  const user = useContext(UserContext);
  const userId = user?.id ?? '';
  const { errors, isDirty, dirtyFields } = formState;
  const { mutate: createAppointment, isLoading: appointmentCreating } = useCreateAppointment(userId);
  const { mutate: updateAppointment, isLoading: appointmentUpdating } = useUpdateAppointment(userId);
  const [showRequestChangeButton, setShowRequestChangeButton] = useState(false);
  const userTimezone = user?.timezone ?? defaultTimeZone;
  const [appointmentDate, setAppointmentDate] = useState<Date>(
    selectedAppointment ? selectedAppointment.appointmentDate : new Date(),
  );
  const [startTime, setStartTime] = useState<Date | undefined>(selectedAppointment?.appointmentTime.timeStart);
  const [endTime, setEndTime] = useState<Date | undefined>(selectedAppointment?.appointmentTime.timeEnd);
  const [duration, setDuration] = useState(0);
  const [selectedProviderId, setSelectedProviderId] = useState(selectedAppointment?.providerUser?.id ?? '');
  const [selectedProviderName, setSelectedProviderName] = useState(
    selectedAppointment?.otherUserAlias ?? selectedAppointment?.otherUserName ?? '',
  );
  const [providerList, setProviderList] = useState<any[]>([]);
  const [locationType, setLocationType] = useState(selectedAppointment?.locationType);
  const [otherUserName, setOtherUserName] = useState('');
  const onCreateAppointment = useDisclosure();
  const updateMessageDisclosure = useDisclosure();
  const [onUpdateMessage, setOnUpdateMessage] = useState('');
  const confirmOverlappingAppointmentDisclosure = useDisclosure();
  const [overlappingAppointments, setOverlappingAppointments] = useState<IAppointment[]>([]);
  const [formValuesToSave, setFormValuesToSave] = useState<FormValues | undefined>(undefined);
  const deviceTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userTzDifferentFromDeviceTz = user?.timezone && deviceTimezone !== user.timezone;

  const formRef = useRef<HTMLFormElement>(null);
  const scrollToForm = () => formRef.current?.scrollIntoView();
  const watchedValues = watch();

  const { closeModal } = useModal();

  const toDisplayCalModal = () => {
    addAppointmentDisclosure.onOpen();
  };

  const resetButtonStates = () => {
    setAppointmentDate(selectedAppointment ? selectedAppointment.appointmentDate : new Date());
    setSelectedProviderName(selectedAppointment?.otherUserAlias ?? selectedAppointment?.otherUserName ?? '');
    setSelectedProviderId(selectedAppointment?.providerUser?.id ?? '');
    setDuration(0);
    setLocationType(selectedAppointment?.locationType);
    setStartTime(selectedAppointment?.appointmentTime?.timeStart);
    setEndTime(selectedAppointment?.appointmentTime?.timeEnd);
    setShowRequestChangeButton(false);

    reset({
      appointmentDate: selectedAppointment?.appointmentDate,
      appointmentTime: selectedAppointment?.appointmentTime,
      providerUserId: selectedAppointment?.providerUser?.id ?? '',
      type: selectedAppointment?.type ?? '',
      notesFromPir: selectedAppointment?.notesFromPir ?? '',
      notesFromProvider: selectedAppointment?.notesFromProvider ?? '',
      approvedByPir: !!selectedAppointment?.approvedByPir,
      approvedByProvider: !!selectedAppointment?.approvedByProvider,
      locationType: selectedAppointment?.locationType,
      location: selectedAppointment?.location,
    });
  };

  const handleModalClose = () => {
    if (!isDirty || window.confirm(t('addAppointmentForm.modalCloseConfirmation'))) {
      resetButtonStates();
      onClose();
    }
  };

  const includesMajorChanges = (dirtyFields: string[]) => {
    return (
      dirtyFields.includes('appointmentDate') ||
      dirtyFields.includes('appointmentTime') ||
      dirtyFields.includes('location') ||
      dirtyFields.includes('locationType')
    );
  };

  useEffect(() => {
    const fields = Object.keys(dirtyFields);

    const dirtyFieldsIncludesMajorChanges = includesMajorChanges(fields);
    if (selectedAppointment && !showRequestChangeButton && dirtyFieldsIncludesMajorChanges) {
      setShowRequestChangeButton(true);
    } else if (showRequestChangeButton && !dirtyFieldsIncludesMajorChanges) {
      setShowRequestChangeButton(false);
    }
  }, [watchedValues, dirtyFields]);

  useEffect(() => {
    if (startTime || endTime) {
      if (startTime) {
        const adjustedStartTime = new Date(
          appointmentDate.getFullYear(),
          appointmentDate.getMonth(),
          appointmentDate.getDate(),
          startTime.getHours(),
          startTime.getMinutes(),
        );
        setValue('appointmentTime.timeStart', adjustedStartTime);
      }
      if (endTime) {
        const adjustedEndTime = new Date(
          appointmentDate.getFullYear(),
          appointmentDate.getMonth(),
          appointmentDate.getDate(),
          endTime.getHours(),
          endTime.getMinutes(),
        );
        setValue('appointmentTime.timeEnd', adjustedEndTime);
      }
    }
  }, [appointmentDate]);

  const onCancel = () => {
    resetButtonStates();
    onClose();
  };

  const displaySaveButton = () => {
    return (
      <FormControl isRequired={true}>
        <Flex justifyContent="space-between">
          <TertiaryButton
            variant="link"
            isDisabled={appointmentCreating || appointmentUpdating}
            mt={10}
            onClick={onCancel}
            isLoading={formState.isSubmitting}
            data-test="cancel-edit-add-appointment"
          >
            {t('appointmentModal.modalCancel')}
          </TertiaryButton>
          <PrimaryButton
            isDisabled={appointmentCreating || appointmentUpdating || !isDirty}
            mt={10}
            isLoading={formState.isSubmitting}
            type="submit"
            data-test="save-appointment"
          >
            {showRequestChangeButton ? t('addAppointmentForm.requestChange') : t('addAppointmentForm.saveButton')}
          </PrimaryButton>
        </Flex>
      </FormControl>
    );
  };

  const calculateDuration = () => {
    if (startTime === undefined || endTime === undefined) {
      setDuration(0);
    } else {
      const lowerDuration = Math.floor((endTime.getTime() - startTime.getTime()) / (60 * 1000));
      if (lowerDuration % 15 === 0) setDuration(lowerDuration);
      else setDuration(lowerDuration + 1);
    }
  };

  const saveAppointment = async (appointment: FormValues): Promise<void> => {
    const utcDate = zonedTimeToUtc(appointment.appointmentDate, userTimezone);

    if (selectedAppointment?.id) {
      const appointmentUpdates: Partial<IAppointment> = {};
      if (wasEdited(selectedAppointment.appointmentDate, utcDate)) {
        appointmentUpdates.appointmentDate = utcDate;
      }
      if (wasEdited(selectedAppointment.notesFromPir, appointment.notesFromPir)) {
        appointmentUpdates.notesFromPir = appointment.notesFromPir;
      }
      if (wasEdited(selectedAppointment.notesFromProvider, appointment.notesFromProvider)) {
        appointmentUpdates.notesFromProvider = appointment.notesFromProvider;
      }
      if (wasEdited(selectedAppointment.type, appointment.type)) {
        appointmentUpdates.type = appointment.type;
      }
      if (
        wasEdited(selectedAppointment.appointmentTime.timeEnd, appointment.appointmentTime.timeEnd) ||
        wasEdited(selectedAppointment.appointmentTime.timeStart, appointment.appointmentTime.timeStart)
      ) {
        appointmentUpdates.appointmentTime = appointment.appointmentTime;
      }

      if (wasEdited(selectedAppointment.location, appointment.location)) {
        appointmentUpdates.location = appointment.location;
      }

      if (wasEdited(selectedAppointment.locationType, appointment.locationType)) {
        appointmentUpdates.locationType = appointment.locationType;
      }

      if (wasEdited(selectedAppointment.duration, duration)) {
        appointmentUpdates.duration = duration;
      }

      const id: string = selectedAppointment.id;

      appointmentUpdates.lastChangeMadeBy = isPir ? UserRole.USER : UserRole.CP;

      // if something significant was changed and this appointment was previously approved by the other user, remove approval and re-ask
      const otherUserName = selectedAppointment.otherUserAlias ?? selectedAppointment.otherUserName;
      const dateString = getAppointmentDateStringWithDay(selectedAppointment, userTimezone);
      const timeString = getAppointmentTimeStringRange(selectedAppointment, userTimezone);

      let message = '';

      if (showRequestChangeButton) {
        appointmentUpdates.appointmentStatus = AppointmentStatus.PENDING;
        message = t('appointmentUpdateModal.notifiedOfRequestChange', { otherUserName });
        if (isPir) {
          appointmentUpdates.approvedByPir = true;
          if (selectedAppointment.approvedByProvider !== undefined) appointmentUpdates.approvedByProvider = undefined;
        } else {
          appointmentUpdates.approvedByProvider = true;
          if (selectedAppointment.approvedByPir !== undefined) appointmentUpdates.approvedByPir = undefined;
        }
      } else {
        // if something less significant was changed like a note or appointment type, no need to request change

        // if already confirmed by both, no need to update approval or status
        if (selectedAppointment.approvedByPir && selectedAppointment.approvedByProvider) {
          message = t('appointmentUpdateModal.userMakingMinorUpdate', { otherUserName });
        } else {
          // if this is the PiR or provider confirming the appointment with non-significant adjustments:
          if ((isPir && selectedAppointment.approvedByPir) || (!isPir && selectedAppointment.approvedByPir)) {
            appointmentUpdates.appointmentStatus = AppointmentStatus.CONFIRMED;
            const howChanged = t('appointmentUpdateModal.confirmed');
            message = t('appointmentUpdateModal.appointmentChangedStatus', {
              otherUserName,
              dateString,
              timeString,
              howChanged,
            });
            if (isPir) appointmentUpdates.approvedByPir = true;
            else appointmentUpdates.approvedByProvider = true;
            // if this is the
          }
          // in all other cases where there already existed an appointment, no additional updates are needed
        }
      }
      setOnUpdateMessage(message);

      await updateAppointment({ id, appointmentUpdates });
      if (message) {
        updateMessageDisclosure.onOpen();
        closeModal();
      }
      resetButtonStates();
      setSelectedAppointment(null);
      onClose();
      const userRef = isPir ? pir : selectedAppointment.providerUser;
      if (userRef) {
        createInteraction({
          pir: userRef,
          dateTime: new Date(),
          moduleName: moduleName.APPOINTMENTS,
          interactionType: interactionType.APPOINTMENTS.EDITED,
        });
      }
    } else {
      const providerId = isPir ? selectedProviderId : userId;
      const providerUser = FirestoreUtils.getDocRef('users', providerId);
      const appointmentToCreate: Omit<IAppointment, 'id'> = {
        pir,
        providerUser,
        appointmentDate: appointment.appointmentTime.timeStart,
        appointmentTime: appointment.appointmentTime,
        duration: duration,
        locationType: appointment.locationType,
        appointmentStatus: AppointmentStatus.PENDING,
        lastChangeMadeBy: isPir ? UserRole.USER : UserRole.CP,
      };
      if (isPir) {
        appointmentToCreate.approvedByPir = true;
        if (appointment.notesFromPir) {
          appointmentToCreate.notesFromPir = appointment.notesFromPir;
        }
      } else {
        appointmentToCreate.approvedByProvider = true;
        if (appointment.notesFromProvider) {
          appointmentToCreate.notesFromProvider = appointment.notesFromProvider;
        }
      }
      if (appointment.type) {
        appointmentToCreate.type = appointment.type;
      }
      if (appointment.location) {
        appointmentToCreate.location = appointment.location;
      }

      const name = isPir
        ? selectedProviderName
        : 'pirAlias' in selectedLinkedUser && selectedLinkedUser.pirAlias
        ? selectedLinkedUser.pirAlias
        : 'preferredName' in selectedLinkedUser && selectedLinkedUser.preferredName
        ? selectedLinkedUser.preferredName
        : 'This patient';
      setOtherUserName(name);
      await createAppointment(appointmentToCreate);
      onCreateAppointment.onOpen();
      resetButtonStates();
      onClose();
      const userRef = isPir ? pir : providerUser;
      createInteraction({
        pir: userRef,
        dateTime: new Date(),
        moduleName: moduleName.APPOINTMENTS,
        interactionType: interactionType.APPOINTMENTS.CREATED,
      });
    }
  };

  const checkForDuplicateAppointments = (appointment: FormValues) => {
    const potentialAppointmentStart = appointment.appointmentTime.timeStart;
    const potentialAppointmentEnd = appointment.appointmentTime.timeEnd;
    const currentAppointmentId = selectedAppointment?.id;
    const overlappingAppointments = getOverlappingAppointments(
      existingAppointments,
      potentialAppointmentStart,
      potentialAppointmentEnd,
      currentAppointmentId,
    );
    if (overlappingAppointments.length) {
      setOverlappingAppointments(overlappingAppointments);
      confirmOverlappingAppointmentDisclosure.onOpen();
      setFormValuesToSave(appointment);
    } else {
      saveAppointment(appointment);
    }
  };

  const addModifiedFieldInteraction = () => {
    const providerUser = isPir ? null : FirestoreUtils.getDocRef('users', userId);
    const userRef = isPir ? pir : providerUser;
    createInteraction({
      pir: userRef,
      dateTime: new Date(),
      moduleName: moduleName.APPOINTMENTS,
      interactionType: interactionType.APPOINTMENTS.MODIFIED_FIELD,
    });
  };

  const getProviderList = (networkMembers: ILinkedUser[]): void => {
    // refactor to forEach or loop
    networkMembers.forEach((networkMember) => {
      if (networkMember.contactAlias) {
        const newArr = providerList;
        newArr.push({ id: networkMember.otherUser?.id, name: networkMember.contactAlias });
        setProviderList([...newArr]);
      } else {
        if (networkMember.otherUser) {
          getUserByRef(networkMember.otherUser).then((v) => {
            if (v !== null) {
              const newArr = providerList;
              newArr.push(v);
              setProviderList([...newArr]);
            }
          });
        }
      }
    });
  };

  const selectedProviderChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === '') {
      setSelectedProviderId('');
      setSelectedProviderName('');
    } else {
      for (const provider of providerList) {
        if (provider.id === event.target.value) {
          setSelectedProviderId(provider.id);
          setSelectedProviderName(provider.name);
        }
      }
    }
    addModifiedFieldInteraction();
  };

  useEffect(() => {
    if (appointmentCreating || appointmentUpdating) {
      setSelectedProviderId('');
      setSelectedProviderName('');
      setAppointmentDate(new Date());
      setStartTime(undefined);
      setEndTime(undefined);
      setDuration(0);
      setShowRequestChangeButton(false);
    }
  }, [appointmentUpdating, appointmentCreating]);

  useEffect(() => {
    if (selectedAppointment) {
      setAppointmentDate(selectedAppointment.appointmentDate);
      setLocationType(selectedAppointment?.locationType);
      reset({
        appointmentDate: selectedAppointment?.appointmentDate,
        appointmentTime: {
          timeStart: selectedAppointment?.appointmentTime.timeStart,
          timeEnd: selectedAppointment?.appointmentTime.timeEnd,
          timeStartString: selectedAppointment?.appointmentTime.timeStartString,
          timeEndString: selectedAppointment?.appointmentTime.timeEndString,
        },
        providerUserId: selectedAppointment?.providerUser?.id,
        approvedByPir: selectedAppointment?.approvedByPir,
        approvedByProvider: selectedAppointment?.approvedByProvider,
        notesFromProvider: selectedAppointment?.notesFromProvider ?? '',
        notesFromPir: selectedAppointment?.notesFromPir ?? '',
        type: selectedAppointment?.type ?? '',
        location: selectedAppointment?.location ?? '',
        locationType: selectedAppointment?.locationType,
      });
    } else {
      resetButtonStates();
    }
  }, [selectedAppointment]);

  useEffect(() => {
    if (isPir) {
      getProviderList(providers);
      if (selectedProviderId) {
        const providerName = providerList.find((provider) => provider.id === selectedProviderId).name;
        setSelectedProviderName(providerName);
      }
    }
    return () => {
      setAppointmentDate(new Date());
      setStartTime(undefined);
      setEndTime(undefined);
      setDuration(0);
      setSelectedProviderId('');
      setSelectedProviderName('');
      setLocationType(undefined);

      setShowRequestChangeButton(false);
      reset({
        appointmentDate: undefined,
        appointmentTime: undefined,
        providerUserId: '',
        type: '',
        notesFromPir: '',
        notesFromProvider: '',
        approvedByPir: false,
        approvedByProvider: false,
        locationType: undefined,
        location: undefined,
      });
    };
  }, []);

  useEffect(() => {
    if (!selectedAppointment) {
      setSelectedProviderName('');
    } else {
      const providerName = selectedAppointment.otherUserAlias || selectedAppointment.otherUserName || '';
      setSelectedProviderName(providerName);
    }
    if (selectedAppointment) {
      setAppointmentDate(selectedAppointment.appointmentDate);
      setDuration(selectedAppointment.duration ?? 0);
      scrollToForm();
    }
    setStartTime(selectedAppointment?.appointmentTime.timeStart);
    setEndTime(selectedAppointment?.appointmentTime.timeEnd);
  }, [selectedAppointment]);

  useEffect(() => {
    calculateDuration();
  }, [startTime, endTime]);

  return (
    <Box>
      <Modal scrollBehavior="inside" isOpen={isOpen} onClose={handleModalClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent maxWidth={'500px'} p={[2, 2, 4, 8]} zIndex={1900} borderRadius="10px">
          <ModalHeader textAlign={'center'} color="purple3.600" fontWeight="bold" fontSize={18} mb={5}>
            <FAIcon icon="calendar-plus" mr={2} />
            {selectedAppointment === null
              ? t('appointments:addAppointmentForm.header')
              : t('appointments:addAppointmentForm.editing')}{' '}
            {selectedAppointment?.otherUserAlias
              ? `${selectedAppointment?.otherUserAlias} (${selectedAppointment?.otherUserName})`
              : selectedAppointment?.otherUserName}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form ref={formRef} autoComplete="off" onSubmit={handleSubmit(checkForDuplicateAppointments)}>
              {/*Choose provider field for PiRs*/}
              {isPir && !selectedAppointment && (
                <Box mb={5}>
                  <Text fontSize={14} mb={3} color="purple3.600">
                    {t('addAppointmentForm.appointmentWithField')}
                  </Text>
                  <FormControl isRequired={true}>
                    <Controller
                      name="providerUserId"
                      control={control}
                      render={({ field }: { field: ControllerRenderProps<FormValues, 'providerUserId'> }) => (
                        <Select
                          {...field}
                          id="providerUserId"
                          onChange={selectedProviderChanged}
                          placeholder={t('addAppointmentForm.providerPlaceholder')}
                          value={selectedProviderId}
                          sx={{
                            border: '1px solid black !important',
                            fontSize: '16px',
                            borderRadius: '2px',
                            '& option': {
                              fontSize: '14px',
                            },
                            '.chakra-select__menu': {
                              position: 'absolute',
                              mt: '0',
                              left: '0',
                              width: '100%',
                            },
                          }}
                        >
                          {providerList.map((provider) => {
                            return (
                              <option key={provider.id} value={provider.id}>
                                {provider.name}
                              </option>
                            );
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Box>
              )}

              {/*appointment type field*/}
              <Text mb={3} fontSize={14} color="purple3.600">
                {t('addAppointmentForm.appointmentType')}
              </Text>
              <FormControl isInvalid={errors.type !== undefined}>
                <Input
                  placeholder={t('addAppointmentForm.appointmentTypePlaceholder')}
                  w="100%"
                  size="md"
                  borderColor="black !important"
                  onFocus={addModifiedFieldInteraction}
                  borderRadius="2px"
                  {...register('type', {
                    required: {
                      value: true,
                      message: t('addAppointmentForm.errors.appointmentTypeRequired'),
                    },
                  })}
                />
                <FormErrorMessage>{errors.type && errors.type.message}</FormErrorMessage>
              </FormControl>

              {/*appointment date field*/}
              <Text mt={5} mb={3} fontSize={14} color="purple3.600">
                {t('addAppointmentForm.appointmentDate')}
              </Text>

              <Flex direction="column">
                <Flex flexDirection="column" alignItems="center">
                  <Text fontSize={14} alignSelf="center">
                    {appointmentDate.toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      timeZone: userTimezone,
                    })}
                  </Text>
                </Flex>
                <Flex mt={3} justifyContent={'center'} color={'#544170'} backgroundColor={'E8E2EF'}>
                  <Controller
                    control={control}
                    name="appointmentDate"
                    render={({
                      field: { onChange },
                    }: {
                      field: ControllerRenderProps<FormValues, 'appointmentDate'>;
                    }) => (
                      <Box className="date-picker-calendar">
                        <ReactDatePicker
                          selected={appointmentDate}
                          onChange={(date) => {
                            const selectedDate = Array.isArray(date) ? date[0] : date;
                            if (selectedDate instanceof Date) {
                              setAppointmentDate(selectedDate);
                              onChange(selectedDate);
                            }
                            addModifiedFieldInteraction();
                          }}
                          onSelect={toDisplayCalModal}
                          shouldCloseOnSelect={false}
                          inline={true}
                          required={true}
                          minDate={new Date()}
                          disabledKeyboardNavigation={true}
                        />
                      </Box>
                    )}
                  />
                </Flex>
              </Flex>

              {/*appointment time field*/}
              <FormControl
                isInvalid={
                  errors.appointmentTime?.timeEnd !== undefined || errors.appointmentTime?.timeStart !== undefined
                }
              >
                <Text fontSize={14} mt={5} color="purple3.600">
                  {t('addAppointmentForm.appointmentTime', { tz: getTz(deviceTimezone) })}
                </Text>
                <Flex flexDirection={'row'} justifyContent={'center'} position="relative">
                  <Flex direction="row" justifyContent={'center'} alignItems="center">
                    <Flex mt={3} maxWidth={isPhone ? '80px' : '75px'}>
                      <Controller
                        control={control}
                        name="appointmentTime.timeStart"
                        defaultValue={undefined}
                        rules={{
                          required: { value: true, message: t('addAppointmentForm.errors.startTimeRequired') },
                          validate: (selectedValue: Date) => {
                            const endVal = getValues('appointmentTime.timeEnd');
                            if (endVal && selectedValue) {
                              return endVal > selectedValue || `{ t('addAppointmentForm.errors.startTimeBeforeEnd') }`;
                            }
                            return true;
                          },
                        }}
                        render={({
                          field: { onChange, value },
                        }: {
                          field: ControllerRenderProps<FormValues, 'appointmentTime.timeStart'>;
                        }) => (
                          <ReactDatePicker
                            selected={value}
                            onChange={(date) => {
                              const selectedDate = Array.isArray(date) ? date[0] : date;
                              if (selectedDate instanceof Date) {
                                const fullDateTime = new Date(
                                  appointmentDate.getFullYear(),
                                  appointmentDate.getMonth(),
                                  appointmentDate.getDate(),
                                  selectedDate.getHours(),
                                  selectedDate.getMinutes(),
                                );
                                setStartTime(fullDateTime);
                                onChange(fullDateTime);
                              } else {
                                setStartTime(undefined);
                                onChange(undefined);
                              }
                              addModifiedFieldInteraction();
                            }}
                            className="input"
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            minTime={isDateToday(appointmentDate) ? new Date() : setHours(setMinutes(new Date(), 0), 0)}
                            maxTime={endTime ? endTime : setHours(setMinutes(new Date(), 59), 23)}
                          />
                        )}
                      />
                    </Flex>
                    <Text mr={2} ml={2}>
                      {' '}
                      {t('addAppointmentForm.to')}{' '}
                    </Text>
                    <Flex mt={3} maxWidth={isPhone ? '80px' : '75px'}>
                      <Controller
                        control={control}
                        name="appointmentTime.timeEnd"
                        defaultValue={undefined}
                        render={({
                          field: { onChange, value },
                        }: {
                          field: ControllerRenderProps<FormValues, 'appointmentTime.timeEnd'>;
                        }) => (
                          <ReactDatePicker
                            selected={value}
                            onChange={(date) => {
                              const selectedDate = Array.isArray(date) ? date[0] : date;
                              if (selectedDate instanceof Date) {
                                const fullDateTime = new Date(
                                  appointmentDate.getFullYear(),
                                  appointmentDate.getMonth(),
                                  appointmentDate.getDate(),
                                  selectedDate.getHours(),
                                  selectedDate.getMinutes(),
                                );
                                setEndTime(fullDateTime);
                                onChange(fullDateTime);
                              } else {
                                setEndTime(undefined);
                                onChange(undefined);
                              }
                              addModifiedFieldInteraction();
                            }}
                            className="input"
                            showTimeSelect={true}
                            showTimeSelectOnly={true}
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            minTime={
                              startTime
                                ? addMinutes(new Date(startTime), 15)
                                : isDateToday(appointmentDate)
                                ? new Date()
                                : setHours(setMinutes(new Date(), 0), 0)
                            }
                            maxTime={getMaxTime(getTz(userTimezone))}
                          />
                        )}
                        rules={{
                          required: { value: true, message: t('addAppointmentForm.errors.endTimeRequired') },
                          validate: (selectedValue: Date) => {
                            const startVal = getValues('appointmentTime.timeStart');
                            if (startVal && selectedValue) {
                              return (
                                startVal < selectedValue || `{ t('addAppointmentForm.errors.startTimeBeforeEnd') }`
                              );
                            }
                            return true;
                          },
                        }}
                      />
                      {duration > 0 && (
                        <Flex
                          position="absolute"
                          left={isPhone ? '80%' : '75%'}
                          transform="translateY(25%)"
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <Text fontSize={14}>
                            ({duration} {t('addAppointmentForm.mins')})
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <Text fontSize={12} mt={1} color="purple3.600">
                  {userTzDifferentFromDeviceTz
                    ? t('addAppointmentForm.diffTimezoneInfo', { timezone: userTimezone })
                    : t('addAppointmentForm.timezoneInfo')}
                </Text>
                <FormErrorMessage>{t('addAppointmentForm.errors.durationError')}</FormErrorMessage>
              </FormControl>

              {/*appointment location field*/}
              <Text mt={5} mb={3} fontSize={14} color="purple3.600">
                {t('addAppointmentForm.appointmentLocation')}
              </Text>
              <FormControl isInvalid={errors.locationType !== undefined}>
                <RadioGroup
                  name="locationType"
                  onChange={(e: LocationType) => {
                    setLocationType(e);
                  }}
                  value={locationType}
                  onFocus={addModifiedFieldInteraction}
                >
                  <Stack spacing={2} direction="column" fontSize={14}>
                    <Radio
                      {...register('locationType', {
                        required: {
                          value: true,
                          message: t('addAppointmentForm.errors.locationTypeRequired'),
                        },
                      })}
                      _checked={radioCheckedStyle}
                      value={LocationType.IN_PERSON}
                    >
                      <Text fontSize="14">{t('addAppointmentForm.inPerson')}</Text>
                    </Radio>
                    <Radio
                      {...register('locationType', {
                        required: {
                          value: true,
                          message: t('addAppointmentForm.errors.locationTypeRequired'),
                        },
                      })}
                      _checked={radioCheckedStyle}
                      value={LocationType.VIRTUAL}
                    >
                      <Text fontSize="14">{t('addAppointmentForm.virtual')}</Text>
                    </Radio>
                  </Stack>
                </RadioGroup>

                <FormErrorMessage>{errors.locationType?.message}</FormErrorMessage>
              </FormControl>

              <FormControl>
                <Input
                  placeholder={t('addAppointmentForm.appointmentLocationPlaceholder')}
                  w="100%"
                  size="md"
                  borderColor="black !important"
                  borderRadius="2px"
                  onFocus={addModifiedFieldInteraction}
                  mt={3}
                  {...register('location')}
                />
                <FormErrorMessage>{errors.location && errors.location.message}</FormErrorMessage>
              </FormControl>

              {/*optional note fields*/}

              {/* PiR note */}

              {isPir ? (
                <>
                  <Text fontSize={14} mt={5} mb={3} color="purple3.600">
                    {t('addAppointmentForm.note')}
                  </Text>
                  <Textarea
                    fontSize={16}
                    size="md"
                    borderColor="black !important"
                    borderRadius="2px"
                    mb={4}
                    {...register('notesFromPir')}
                    defaultValue={selectedAppointment?.notesFromPir}
                    placeholder={t('addAppointmentForm.pirNoteInstruction')}
                    onFocus={addModifiedFieldInteraction}
                  />
                </>
              ) : selectedAppointment?.notesFromPir ? (
                <>
                  <Text fontSize={14} color="purple3.600" mt={5} mb={3}>
                    {t('addAppointmentForm.pirNoteLabelForCP')}
                  </Text>
                  <Box p={4} fontSize={14} border="1px" borderColor="purple3.600" borderRadius="2px" overflowY="auto">
                    <Text color="purple3.600">{selectedAppointment?.notesFromPir}</Text>
                  </Box>
                </>
              ) : null}

              {/* CP note */}
              {!isPir ? (
                <>
                  <Text fontSize={14} mt={5} mb={3} color="purple3.600">
                    {t('addAppointmentForm.note')}
                  </Text>
                  <Textarea
                    fontSize={16}
                    size="md"
                    borderColor="black !important"
                    borderRadius="2px"
                    mb={4}
                    {...register('notesFromProvider')}
                    defaultValue={selectedAppointment?.notesFromProvider}
                    placeholder={t('addAppointmentForm.cpNoteInstruction')}
                    onFocus={addModifiedFieldInteraction}
                  />
                </>
              ) : selectedAppointment?.notesFromProvider ? (
                <>
                  <Text fontSize={14} color="purple3.600" mt={2}>
                    {t('addAppointmentForm.cpNoteLabelForPir')}
                  </Text>
                  <Box p={4} border="1px" borderColor="purple3.600" borderRadius="2px" overflowY="auto">
                    <Text color="purple3.600">{selectedAppointment?.notesFromProvider}</Text>
                  </Box>
                </>
              ) : null}

              {/* Changes to appointment */}
              {selectedAppointment &&
                !!Object.keys(dirtyFields).length &&
                !(appointmentCreating || appointmentUpdating) && (
                  <Box fontSize={14} color="purple3.600" mt={5}>
                    <Text>{t('addAppointmentForm.current')}</Text>
                    <Box fontSize={14} ml={3} color="purple3.600" mb={3}>
                      {Object.keys(dirtyFields).includes('type') && <Text>{selectedAppointment.type}</Text>}
                      {Object.keys(dirtyFields).includes('appointmentDate') && (
                        <Text>{selectedAppointment.appointmentDate.toLocaleDateString(undefined, optionHeader)}</Text>
                      )}
                      {Object.keys(dirtyFields).includes('appointmentTime') && (
                        <Text>
                          {getAppointmentTimeStringWithDuration(
                            formatTimeStringFromDateTimeAndTz(
                              selectedAppointment.appointmentDate,
                              selectedAppointment.appointmentTime.timeStart,
                              deviceTimezone,
                            ),
                            formatTimeStringFromDateTimeAndTz(
                              selectedAppointment.appointmentDate,
                              selectedAppointment.appointmentTime.timeEnd,
                              deviceTimezone,
                            ),
                            selectedAppointment.duration as number,
                            deviceTimezone,
                          )}
                        </Text>
                      )}
                      {Object.keys(dirtyFields).includes('locationType') && (
                        <Text>{selectedAppointment.locationType}</Text>
                      )}
                      {Object.keys(dirtyFields).includes('location') && <Text>{selectedAppointment.location}</Text>}
                      {Object.keys(dirtyFields).includes('notesFromPir') && (
                        <Text>{selectedAppointment.notesFromPir}</Text>
                      )}
                      {Object.keys(dirtyFields).includes('notesFromProvider') && (
                        <Text>{selectedAppointment.notesFromProvider}</Text>
                      )}
                    </Box>

                    <Text>
                      {includesMajorChanges(Object.keys(dirtyFields))
                        ? t('addAppointmentForm.new')
                        : t('addAppointmentForm.changes')}
                    </Text>
                    <Box fontSize={14} ml={3} color="purple3.600" fontWeight="bold">
                      {Object.keys(dirtyFields).includes('type') && <Text>{getValues('type')}</Text>}
                      {Object.keys(dirtyFields).includes('appointmentDate') && (
                        <Text>{appointmentDate.toLocaleDateString(undefined, optionHeader)}</Text>
                      )}
                      {Object.keys(dirtyFields).includes('appointmentTime') && (
                        <Text>
                          {getAppointmentTimeStringWithDuration(
                            formatTimeStringFromDateTimeAndTz(
                              getValues('appointmentDate'),
                              getValues('appointmentTime').timeStart,
                              deviceTimezone,
                            ),
                            formatTimeStringFromDateTimeAndTz(
                              getValues('appointmentDate'),
                              getValues('appointmentTime').timeEnd,
                              deviceTimezone,
                            ),
                            duration as number,
                            deviceTimezone,
                          )}
                        </Text>
                      )}
                      {Object.keys(dirtyFields).includes('locationType') && <Text>{getValues('locationType')}</Text>}
                      {Object.keys(dirtyFields).includes('location') && <Text>{getValues('location')}</Text>}
                      {Object.keys(dirtyFields).includes('notesFromPir') && <Text>{getValues('notesFromPir')}</Text>}
                      {Object.keys(dirtyFields).includes('notesFromProvider') && (
                        <Text>{getValues('notesFromProvider')}</Text>
                      )}
                    </Box>
                  </Box>
                )}

              <Center mt={3}>{displaySaveButton()}</Center>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <OnCreateAppointmentModal
        isOpen={onCreateAppointment.isOpen}
        onClose={() => {
          onCreateAppointment.onClose();
          resetButtonStates();
        }}
        otherUserName={otherUserName}
      />
      <OnUpdateAppointmentModal
        isOpen={updateMessageDisclosure.isOpen}
        onClose={() => {
          updateMessageDisclosure.onClose();
          setSelectedAppointment(null);
        }}
        onUpdateMessage={onUpdateMessage}
      />
      <ConfirmOverlappingAppointmentsModal
        isOpen={confirmOverlappingAppointmentDisclosure.isOpen}
        onClose={() => {
          confirmOverlappingAppointmentDisclosure.onClose();
        }}
        overlappingAppointments={overlappingAppointments}
        saveAppointment={saveAppointment}
        appointment={formValuesToSave}
        userTimezone={userTimezone}
      />
    </Box>
  );
};

const mapDispatchToProps = {
  createInteraction,
};

function mapStateToProps(state: RootState) {
  const { selectedLinkedUser } = state.linkedUsers;
  const { user } = state.user;

  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }
  if (user === null || !user.id) {
    throw new Error("User shouldn't be null");
  }

  return {
    pir: selectedLinkedUser.pir,
    selectedLinkedUser,
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AddAppointmentForm);
