import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Heading, Text, Button, useToast, Spinner } from '@chakra-ui/react';
import PrimaryButton from '../../components/PrimaryButton';
import PageHeading from '../../components/PageHeading';
import { createDeviceGarmin, deregisterGarminAPI, updateDeviceGarmin } from '../deviceGarmin/actions';
import IDeviceGarmin from '../deviceGarmin/interfaces/IDeviceGarmin';
import { RootState } from '../../redux/store';
import { createInteraction } from '../interactions/actions';
import { moduleName, interactionType } from '../interactions/constants';
import NarrowInterior from '../../components/NarrowInterior';
import SelectLinkedUser from '../linked-users/SelectLinkedUser';
import {
  BluetoothAuthState,
  BluetoothStatus,
  checkBluetoothAuthStatus,
  isThisAndroid,
  requestBluetoothAuth,
  wearablePair,
  getWearableInfo,
} from '../../lib/wearable';
// import { geolocationStatusOn, geolocationStatusOff } from '../../redux/actions/garmin';
import FAIcon from '../../components/FAIcon';
import { useAnimation, motion } from 'framer-motion';
import { UserRole } from '../user/interfaces/IUser';
import { selectLinkedUser } from '../linked-users/actions';
import { IPirLinkedUser } from '../linked-users/interfaces/ILinkedUser';
import { bluetoothAuthCheckNeeded, stepsToConnect } from './deviceHelpers';
import { ConnectType } from './DevicePage';
import PairAlertDialog from './PairAlertDialog';
import { useDevice } from 'src/DeviceContext';

const DeviceOnboarding = ({
  selectLinkedUser,
  selectedLinkedUser,
  // geolocationEnabled,
  createInteraction,
  createDeviceGarmin,
  userRole,
  pirLinkedUser,
}: PropsFromRedux): React.ReactElement | null => {
  const { t } = useTranslation('device');
  const { isAndroid, isMobileDevice, isCordova } = useDevice();
  const history = useHistory();
  // const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const MotionHeading = motion(Heading);
  const MotionFlex = motion(Flex);
  const headerControls = useAnimation();
  const stepTwoControls = useAnimation();
  const stepThreeControls = useAnimation();
  const stepFourControls = useAnimation();
  const [isPairing, setIsPairing] = useState(false);
  const garminDevice = useSelector((state: RootState) => state.garmin);
  const toast = useToast();
  const bluetoothStatus = garminDevice.bluetoothStatus;
  const [needsToAuthorizeBluetooth, setNeedsToAuthorizeBluetooth] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);

  const beforePair = async () => {
    try {
      if (bluetoothStatus === BluetoothStatus.BLUETOOTH_ON) {
        await nextWearablePair();
        await getWearableInfo();
      } else if (bluetoothStatus === BluetoothStatus.UNAUTHORIZED) {
        const authStatus = await checkBluetoothAuthStatus();
        if (authStatus === BluetoothAuthState.NOT_REQUESTED) {
          await requestBluetoothAuth();
        } else {
          setNeedsToAuthorizeBluetooth(true);
          setAlertDialogOpen(true);
        }
      } else {
        setAlertDialogOpen(true);
      }
    } catch (err) {
      console.error('Bluetooth status error:', err);
      let displayError: string | null = typeof err === 'string' ? err : JSON.stringify(err);
      if (isThisAndroid() && err === 'Device scan failed with error code 3') {
        const bluetoothAuthWasNeeded = await bluetoothAuthCheckNeeded();
        if (bluetoothAuthWasNeeded) {
          await nextWearablePair();
          displayError = null;
        }
      }
      if (displayError) {
        toast({
          title: 'Bluetooth status error',
          description: displayError,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      }
    }
  };

  const nextWearablePair = async () => {
    setIsPairing(true);
    try {
      await wearablePair();
    } catch (pairError: unknown) {
      setIsPairing(false);
      console.error('Wearable pair error:', pairError);
      if (isThisAndroid() && pairError == 'Device scan failed with error code 3') {
        throw pairError;
      } else {
        let errorText = typeof pairError === 'string' ? pairError : JSON.stringify(pairError);
        if (errorText.toLowerCase() === 'no device found') {
          errorText = t('deviceModal.noDeviceFound');
        }
        toast({
          title: 'Pair error',
          description: errorText,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      }
    } finally {
      setIsPairing(false);
    }
    secondStepDone();
  };

  // const geoOn = function () {
  //   dispatch(geolocationStatusOn());
  // };
  // const geoOff = function () {
  //   dispatch(geolocationStatusOff());
  // };

  const garminDeviceAPI = useSelector((state: RootState): IDeviceGarmin | null => state.deviceGarmin.deviceGarmin);

  // const addModifiedFieldInteraction = () => {
  //   createInteraction({
  //     pir: selectedLinkedUser?.pir || null,
  //     dateTime: new Date(),
  //     moduleName: moduleName.DEVICE,
  //     interactionType: interactionType.DEVICE.MODIFIED_FIELD,
  //   });
  // };

  useEffect(() => {
    const createInteractionForUser = async () => {
      if (
        (userRole === UserRole.CP && !selectedLinkedUser?.pir) ||
        (userRole === UserRole.USER && !pirLinkedUser) ||
        !isMobileDevice ||
        !isCordova
      ) {
        return;
      }

      if (!selectedLinkedUser?.pir) {
        await selectLinkedUser(pirLinkedUser);
      }

      createInteraction({
        pir: selectedLinkedUser?.pir || (pirLinkedUser as IPirLinkedUser).pir,
        dateTime: new Date(),
        moduleName: moduleName.DEVICE,
        interactionType: interactionType.DEVICE.OPEN_MODAL,
      });
    };
    createInteractionForUser();
  }, [selectedLinkedUser]);

  if (userRole && userRole !== UserRole.USER && selectedLinkedUser === null) {
    return (
      <NarrowInterior>
        <PageHeading mb={8}>Please select a patient to continue:</PageHeading>
        <SelectLinkedUser />
      </NarrowInterior>
    );
  }

  if (selectedLinkedUser === null) {
    return (
      <Flex alignItems="center" justifyContent="center" h="100%">
        <Box w="100%" h="25%" textAlign="center">
          <Spinner />
          <Text mt={4} fontSize={24} fontWeight="bold">
            Loading
          </Text>
        </Box>
      </Flex>
    );
  }

  const { pir } = selectedLinkedUser;

  if (pir === null || pir === undefined) {
    return null;
  }

  // for Garmin API Auth flow
  const url = process.env.REACT_APP_GARMIN_API_ENDPOINT;
  const redirectUriGarmin = process.env.REACT_APP_GARMIN_API_REDIRECT;
  const openInAppBrowser = () => {
    const browser = window.cordova.InAppBrowser.open(url, '_blank', 'location=yes');
    browser.addEventListener('loadstart', (event: any) => {
      if (event.url.indexOf(redirectUriGarmin) === 0) {
        const match = event.url.match(/\?.*/);
        if (match && match.length > 0) {
          const query = new URLSearchParams(match[0]);
          const userId = query.get('garminId');

          if (!userId) {
            return;
          }

          if (garminDeviceAPI?.userId && garminDeviceAPI?.status === 'AUTHORIZED') {
            return;
          }

          createDeviceGarmin({
            pir,
            userId,
            status: 'AUTHORIZED',
          });
        }

        browser.close();
      }
    });
    browser.addEventListener('loaderror', (err: any) => {
      console.error(err);
    });
  };

  const controlsGarminAPI = (
    <>
      {garminDeviceAPI && garminDeviceAPI.status === 'AUTHORIZED' ? (
        <PrimaryButton onClick={() => deregisterGarminAPI(garminDeviceAPI)} mt={3} mb={3} alignItems="center">
          {/* {t('deviceModal.syncButton')} */}
          Disconnect Garmin&#8482; API
        </PrimaryButton>
      ) : (
        <PrimaryButton onClick={openInAppBrowser} mt={3} mb={3} alignItems="center">
          {/* {t('deviceModal.syncButton')} */}
          Connect Garmin&#8482; API
        </PrimaryButton>
      )}
    </>
  );

  const firstStepDone = async (): Promise<void> => {
    setStep(2);
    stepTwoControls.set({ opacity: 0 });
    return stepTwoControls.start({ opacity: 1, transition: { duration: 0.5 } });
  };

  const secondStepDone = async (): Promise<void> => {
    setStep(3);
    stepThreeControls.set({ opacity: 0 });
    return stepThreeControls.start({ opacity: 1, transition: { duration: 0.5 } });
  };

  const thirdStepDone = async (): Promise<void> => {
    history.push('/contact');
    stepFourControls.start({ opacity: 0, transition: { delay: 0.5, duration: 0.5 } });
    await headerControls.start({ opacity: 0, transition: { delay: 0.5, duration: 0.5 } });
  };

  return (
    <NarrowInterior>
      <MotionHeading as="h2" mt={4} fontSize={24} fontWeight="light" color="purple3.600" animate={headerControls}>
        {t('deviceOnboarding.header')}
      </MotionHeading>
      {/* Enabling location services */}
      <Box width="100%" height="100%" mt={6} display={step === 1 ? 'block' : 'none'}>
        <Flex pb={2} flexDirection="column">
          <Text fontSize={24} fontWeight="light" color="purple3.600">
            {t('deviceOnboarding.locationAuth')}
          </Text>
          <Text mt={4} fontSize="12">
            Recovery collects location data to enable better predictions of upcoming cravings. Specifically, it uses
            changes in your GPS location to help predict upcoming cravings, so the application will request access to
            your GPS. Recovery records your location in the background and when the application is closed. Location data
            uploaded from your phone will be transformed on the server into movement rather than coordinates and
            coordinates will be deleted from the server in a timely manner. These data are not and will not be sold. For
            additional information, see Behaivior&lsquo;s{' '}
            <a href="https://behaivior.com/privacy-policy">privacy policy</a>.
          </Text>
        </Flex>
        <hr />
        {/* <Flex py={2} mt={4}>
          <Heading as="h4" fontSize={18} fontWeight="normal">
            Location Usage
          </Heading>
          <Spacer />
          <Switch
            size="lg"
            color="green"
            isChecked={geolocationEnabled}
            onChange={() => {
              !geolocationEnabled ? geoOn() : geoOff();
              addModifiedFieldInteraction();
            }}
          />
        </Flex>
        <hr /> */}
        <Flex py={2}>
          <Button
            fontSize={[12, 16]}
            mt={2}
            mr={4}
            onClick={() => {
              firstStepDone();
            }}
          >
            {t('deviceOnboarding.nextButton')}
          </Button>
        </Flex>
      </Box>
      {/* Connecting Garmin Device */}
      <MotionFlex flexDir="column" mt={6} animate={stepTwoControls} display={step === 2 ? 'block' : 'none'}>
        <Flex pb={2} flexDirection="column">
          <Text fontSize={24} fontWeight="light" color="purple3.600">
            {t('deviceOnboarding.deviceAuth')}
          </Text>
          <Flex alignItems="left" direction="column" width="100%">
            {/* We can't try to pair if bluetooth is unauthorized because changing bluetooth authorization reloads the app and we can't do that in onboarding */}
            {bluetoothStatus === BluetoothStatus.UNAUTHORIZED && isAndroid === false ? (
              <Text color="red" fontSize={14} pl={{ base: 4, md: 8 }}>
                {t('deviceOnboarding.mustPairLater')}
              </Text>
            ) : (
              <>
                {stepsToConnect(ConnectType.PAIR, bluetoothStatus, t, true)}
                <Text fontSize={14} pl={{ base: 4, md: 8 }}>
                  {t('deviceOnboarding.pairLater')}
                </Text>
              </>
            )}
            <br />
            <Flex direction="column">
              <PrimaryButton
                width="100%"
                onClick={beforePair}
                my={3}
                alignItems="center"
                disabled={isPairing || (bluetoothStatus === BluetoothStatus.UNAUTHORIZED && isAndroid === false)}
              >
                &nbsp;{t('deviceModal.pairButton')}&nbsp;{isPairing && ' '}
                {isPairing && <FAIcon icon="sync-alt" className={!garminDevice.name && isPairing ? 'fa-spin' : ''} />}
              </PrimaryButton>
            </Flex>
          </Flex>
        </Flex>
        <hr />
        <Flex mt={2} py={2}>
          <Button
            fontSize={[12, 16]}
            mt={2}
            mr={4}
            onClick={() => {
              secondStepDone();
            }}
          >
            {t('deviceOnboarding.nextButton')}
          </Button>
        </Flex>
      </MotionFlex>
      {/* Pairing Garmin API */}
      <MotionFlex flexDir="column" mt={6} animate={stepThreeControls} display={step === 3 ? 'block' : 'none'}>
        <Flex pb={2} flexDirection="column">
          <Text fontSize={24} fontWeight="light" color="purple3.600">
            Pair Garmin&#8482; API
          </Text>
          <Text mt={4} fontSize="12">
            Pair <i>Recovery</i> with the Garmin&#8482; API so that it can access your Garmin&#8482; device and click
            the <strong>next</strong> button when done.
          </Text>
          {controlsGarminAPI}
        </Flex>
        <hr />
        <Flex mt={2} py={2}>
          <Button
            fontSize={[12, 16]}
            mt={2}
            mr={4}
            onClick={() => {
              thirdStepDone();
            }}
          >
            {t('deviceOnboarding.nextButton')}
          </Button>
        </Flex>
      </MotionFlex>
      {/* Alert user if bluetooth isn't enabled */}
      <PairAlertDialog
        isOpen={alertDialogOpen}
        setAlertDialogOpen={setAlertDialogOpen}
        needsToAuthorizeBluetooth={needsToAuthorizeBluetooth}
      />
    </NarrowInterior>
  );
};

const mapStateToProps = (state: RootState) => {
  const { selectedLinkedUser } = state.linkedUsers;
  const enabled = state.garmin.geolocationStatus;
  const userRole = state.user?.user?.role;

  // if (!selectedLinkedUser) {
  //   throw 'Error: No selected linked user.';
  // }

  return {
    selectedLinkedUser,
    geolocationEnabled: enabled,
    userRole,
    pirLinkedUser: state.linkedUsers.pir,
  };
};

const mapDispatchToProps = {
  createInteraction,
  createDeviceGarmin,
  updateDeviceGarmin,
  selectLinkedUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DeviceOnboarding);
