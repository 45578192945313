import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import AppointmentModal from '../../components/ManageAppointmentModal/AppointmentModal';
import ILinkedUser, { LinkedUserRole } from '../linked-users/interfaces/ILinkedUser';
import { isLinkedUser } from '../linked-users/utils';
import ModuleContainer from '../../components/ModuleContainerV2';
import { createInteraction } from '../interactions/actions';
import IAppointment, { AppointmentColor, AppointmentStatus, AppointmentType } from './interfaces/IAppointment';
import AppointmentItemList from './appointmentComponents/AppointmentItemList';
import { moduleName, interactionType } from '../interactions/constants';
import FAIcon from 'src/components/FAIcon';
import { AppointmentsContext, UserContext } from '../interior/Index';
import AllAppointmentsModal from 'src/components/ManageAppointmentModal/AllAppointmentsModal';
import AddAppointmentForm from 'src/components/ManageAppointmentModal/AddAppointmentForm';
import { formatTimeStringFromDateTimeAndTz } from 'src/components/ManageAppointmentModal/utils';
import { useWindowWidth } from 'src/hooks/utils';
import { useModal } from 'src/ModalContext';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  isPir: boolean;
}

type Props = SuppliedProps & PropsFromRedux;

const MODAL_NAME = 'appointments';

const AppointmentModule = ({
  supportNetwork,
  createInteraction,
  isPir,
  pir,
  selectedLinkedUser,
}: Props): React.ReactElement => {
  const { t } = useTranslation('appointments');
  const [selectedAppointment, setSelectedAppointment] = useState<IAppointment | null>(null);
  const [keepAppointmentModalOpen, setKeepAppointmentModalOpen] = useState(false);
  const { modal, openModal, closeModal } = useModal();
  const viewAppointment = useDisclosure({ isOpen: !!selectedAppointment });
  const user = useContext(UserContext);

  const { appointments, setRefresh } = useContext(AppointmentsContext);

  const history = useHistory();
  const { isPhone, isTablet } = useDevice();
  const [confirmedUpcomingAppts, setConfirmedUpcomingAppts] = useState<IAppointment[]>([]);
  const [confirmedPastAppts, setConfirmedPastAppts] = useState<IAppointment[]>([]);
  const [apptsToConfirm, setApptsToConfirm] = useState<IAppointment[]>([]);
  const [pendingAppts, setPendingAppts] = useState<IAppointment[]>([]);
  const [apptsForOtherPirs, setApptsForOtherPirs] = useState<IAppointment[]>([]);
  const addAppointmentDisclosure = useDisclosure();
  const [appointmentType, setAppointmentType] = useState<AppointmentType | null>(null);
  const [isTodo, setTodo] = useState(false);

  const limitAppointments = (appointments: IAppointment[], limit: number) => {
    return appointments.slice(0, limit);
  };

  const windowWidth = useWindowWidth();

  const getTabletLimit = useCallback(() => {
    const appointmentWidth = 240;
    const padding = 16;
    if (windowWidth <= appointmentWidth * 2 + padding) return 1;
    // how many appointments would fit

    const limit = 1 + (windowWidth - appointmentWidth) / (appointmentWidth + padding);
    return limit > 4 ? 4 : Math.floor(limit);
  }, [windowWidth]);

  const [limit, setLimit] = useState<number>(isPhone ? 2 : isTablet ? getTabletLimit() : 4);

  useEffect(() => {
    if (isTablet) {
      const tabletLimit = getTabletLimit();
      setLimit(tabletLimit);
    }
  }, [windowWidth]);

  // To track PIR interactions
  const addClosedInteraction = useCallback(() => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.APPOINTMENTS,
      interactionType: interactionType.APPOINTMENTS.CLOSED,
    });
  }, [pir]);

  const onClose = () => {
    history.goBack();
    viewAppointment.onClose();
    setSelectedAppointment(null);
    if (!keepAppointmentModalOpen) closeModal();
    if (isPir) addClosedInteraction();
  };

  useEffect(() => {
    if (!isPir) setRefresh(true);
  }, []);

  useEffect(() => {
    if (!appointments || !appointments.length) return;
    const confirmedUpcoming: IAppointment[] = [];
    const confirmedPast: IAppointment[] = [];
    const pending: IAppointment[] = [];
    const toConfirm: IAppointment[] = [];
    // we need to include confirmed and pending appointments for other PiRs to inform CPs of any conflicting appointments
    const otherPir: IAppointment[] = [];
    const sortedAppointments = [...appointments].sort(
      (o1, o2) => o1.appointmentDate.getTime() - o2.appointmentDate.getTime(),
    );

    sortedAppointments.forEach((appointment) => {
      const apptForThisPir = isPir ? true : appointment.pir?.id === pir.id;
      appointment.appointmentTime.timeStartString = formatTimeStringFromDateTimeAndTz(
        appointment.appointmentDate,
        appointment.appointmentTime.timeStart,
        user?.timezone,
      );
      appointment.appointmentTime.timeEndString = formatTimeStringFromDateTimeAndTz(
        appointment.appointmentDate,
        appointment.appointmentTime.timeEnd,
        user?.timezone,
      );
      if (appointment.appointmentStatus === AppointmentStatus.CONFIRMED) {
        if (appointment.appointmentTime.timeEnd >= new Date()) {
          if (apptForThisPir) confirmedUpcoming.push(appointment);
          else otherPir.push(appointment);
        } else if (apptForThisPir) {
          confirmedPast.push(appointment);
        }
      } else if (
        appointment.appointmentTime.timeEnd >= new Date() &&
        appointment.appointmentStatus === AppointmentStatus.PENDING
      ) {
        // we don't want to show any non-confirmed past appointments or any that are canceled or declined
        if (appointment.approvedByPir) {
          if (isPir) pending.push(appointment);
          else if (apptForThisPir) toConfirm.push(appointment);
        } else if (appointment.approvedByProvider) {
          if (isPir) toConfirm.push(appointment);
          else if (apptForThisPir) pending.push(appointment);
          else otherPir.push(appointment);
        }
      }
      setConfirmedUpcomingAppts(confirmedUpcoming);
      setConfirmedPastAppts(confirmedPast);
      setPendingAppts(pending);
      setApptsToConfirm(toConfirm);
      setApptsForOtherPirs(otherPir);
    });
  }, [appointments, user?.timezone]);

  // If pir, get the providers of the pir
  const approvedProviders: ILinkedUser[] = [];
  supportNetwork.forEach((networkMember) => {
    if (isLinkedUser(networkMember)) {
      if (networkMember.otherUserRole === LinkedUserRole.PROVIDER) {
        approvedProviders.push(networkMember);
      }
    }
  });

  useEffect(() => {
    const url = window.location.href;
    const appointmentRoute = url.substring(url.lastIndexOf('/') + 1);
    const appointmentIdRoute = appointmentRoute.split("?")[0];

    if (appointmentIdRoute !== MODAL_NAME && appointmentIdRoute !== 'home' && appointments?.length) {
      for (let i = 0; i < appointments.length; i++) {
        if (appointments[i].id === appointmentIdRoute) setSelectedAppointment(appointments[i]);
      }
    } else {
      setSelectedAppointment(null);
    }

    const isFromTodo = appointmentRoute.split("=")[1]

    if (isFromTodo) {
      setTodo(true)
    } else {
      setTodo(false)
    }

  }, [window.location.href, appointments]);

  return (
    <Box>
      <ModuleContainer
        title={t('appointmentModule.moduleHeader')}
        icon="calendar-plus"
        modalName={MODAL_NAME}
        modalText={t('appointmentModule.seeAll')}
        moduleButton={t('appointmentModule.moduleButton')}
        cogFunction={() => {
          setKeepAppointmentModalOpen(true);
          openModal(MODAL_NAME);
        }}
      >
        <VStack spacing={['20px', '20px']}>
          {appointments?.length ? (
            <>
              {!isPhone && (
                <Flex width="100%" justifyContent="flex-start">
                  <Text fontWeight="semibold" fontSize="14" color={AppointmentColor.UPCOMING}>
                    {t('appointmentModule.upcoming')}
                  </Text>
                </Flex>
              )}
              <Flex
                width="100%"
                mt={'-10px'}
                padding="0px"
                justifyContent="space-between"
                flexDirection={['column', 'row']}
              >
                <AppointmentItemList
                  appointments={limitAppointments(confirmedUpcomingAppts, limit)}
                  emptyInstruct={t('appointmentModule.noAppointment', { appointmentType: AppointmentType.UPCOMING })}
                  setSelectedAppointment={setSelectedAppointment}
                  selectedAppointment={selectedAppointment}
                  appointmentColor={AppointmentColor.UPCOMING}
                  isPir={isPir}
                />
              </Flex>
              {apptsToConfirm.length ? (
                <>
                  {isPhone ? (
                    <Flex flexDirection={'row'} justifyContent={'flex-start'} width="100%">
                      <Link
                        to={`/home/${MODAL_NAME}`}
                        onClick={() => {
                          setAppointmentType(AppointmentType.CONFIRM);
                          openModal(MODAL_NAME);
                        }}
                      >
                        <Flex color="#F19442" flexDirection={'row'} justifyContent={'flex-start'} width="100%">
                          <FAIcon icon="clock" fontSize={16} style={{ marginRight: 6 }} />
                          <Text fontWeight="bold" fontSize={16}>
                            {' '}
                            {t('appointmentModule.responseNeeded')} {`(${apptsToConfirm.length})`}
                          </Text>
                        </Flex>
                      </Link>
                    </Flex>
                  ) : (
                    <>
                      <Flex width="100%" justifyContent="flex-start">
                        <Text fontWeight="semibold" fontSize="14" color={AppointmentColor.CONFIRM}>
                          {t('allAppointmentsModal.confirmHeader')} {`(${apptsToConfirm.length})`}
                        </Text>
                      </Flex>
                      <Flex width="100%" padding="0px" justifyContent="space-between" flexDirection={['row']}>
                        <AppointmentItemList
                          appointments={limitAppointments(apptsToConfirm, limit)}
                          emptyInstruct={t('appointmentModule.noAppointment', {
                            appointmentType: AppointmentType.CONFIRM,
                          })}
                          setSelectedAppointment={setSelectedAppointment}
                          selectedAppointment={selectedAppointment}
                          appointmentColor={AppointmentColor.CONFIRM}
                          isPir={isPir}
                        />
                      </Flex>
                    </>
                  )}
                </>
              ) : null}
            </>
          ) : (
            <Flex width="100%" padding="0px 10px 10px 10px">
              <Text fontSize="14px">
                {isPir
                  ? [t('pirAppointmentModule.moduleInstruct')]
                  : [
                    t('cpAppointmentModule.moduleInstruct', {
                      patient:
                        'preferredName' in selectedLinkedUser && selectedLinkedUser.preferredName
                          ? selectedLinkedUser.preferredName
                          : 'pirAlias' in selectedLinkedUser && selectedLinkedUser.pirAlias
                            ? selectedLinkedUser.pirAlias
                            : 'this patient',
                    }),
                  ]}
              </Text>
            </Flex>
          )}

          <Flex
            padding="10px 28px 10px 28px"
            height="39px"
            backgroundColor="#2680D0"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            onClick={() => {
              addAppointmentDisclosure.onOpen();
            }}
          >
            <Text color="white" fontWeight={700} fontSize="16px">
              {t('appointmentModule.requestAppointment')}
            </Text>
          </Flex>
        </VStack>
      </ModuleContainer>

      <AppointmentModal
        isPir={isPir}
        isTodo={isTodo}
        setSelectedAppointment={setSelectedAppointment}
        selectedAppointment={selectedAppointment}
        isOpen={viewAppointment.isOpen}
        onOpen={viewAppointment.onOpen}
        selectedLinkedUser={selectedLinkedUser}
        onClose={onClose}
        providers={approvedProviders}
        createInteraction={createInteraction}
        existingAppointments={[...confirmedUpcomingAppts, ...pendingAppts, ...apptsForOtherPirs]}
      />
      <AddAppointmentForm
        isOpen={addAppointmentDisclosure.isOpen}
        onClose={addAppointmentDisclosure.onClose}
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
        providers={approvedProviders}
        isPir={isPir}
        existingAppointments={[...confirmedUpcomingAppts, ...pendingAppts, ...apptsForOtherPirs]}
      />
      <AllAppointmentsModal
        isOpen={modal === MODAL_NAME && !selectedAppointment}
        onOpen={() => {
          setKeepAppointmentModalOpen(true);
          openModal(MODAL_NAME);
        }}
        onClose={() => {
          setKeepAppointmentModalOpen(false);
          closeModal();
        }}
        providers={approvedProviders}
        isPir={isPir}
        confirmedUpcomingAppts={confirmedUpcomingAppts}
        confirmedPastAppts={confirmedPastAppts}
        apptsToConfirm={apptsToConfirm}
        pendingAppts={pendingAppts}
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
        appointmentType={appointmentType}
        setAppointmentType={setAppointmentType}
        openAddAppointment={() => addAppointmentDisclosure.onOpen()}
        limit={limit}
      />
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  const { selectedLinkedUser } = state.linkedUsers;

  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }

  return {
    pir: selectedLinkedUser.pir,
    supportNetwork: state.linkedUsers.supportNetwork ?? [],
    today: new Date(),
    selectedLinkedUser,
  };
};

const mapDispatchToProps = {
  createInteraction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AppointmentModule);
