import React, { useState, useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { Accordion } from '@chakra-ui/accordion';
import ModuleContainer from '../../components/ModuleContainerV2';
import VerticalListResourceItem from '../../components/ManageResourceModal/VerticalListResourceItem';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  List,
  ListItem,
} from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import { createInteraction } from '../interactions/actions';
import { moduleName, interactionType } from '../interactions/constants';
import ResourceModal from '../../components/ManageResourceModal/ResourceModal';
import DisplayWebLink from '../../components/DisplayWebLink';

import { IResource } from '../../db/resources';
import { useQueryResources } from './queryHooks';
import { useDevice } from 'src/DeviceContext';
import { useModal } from 'src/ModalContext';

const MODAL_NAME = 'resources';

interface Props {
  isPir: boolean;
}

const ResourceModule = ({ pir, createInteraction, isPir }: Props & PropsFromRedux): React.ReactElement => {
  const { t } = useTranslation('resources');
  const { isPhone } = useDevice();
  const [selectedResource, setSelectedResource] = useState<IResource | null>(null);

  const { modal, openModal, closeModal } = useModal();

  const url = window.location.href;
  const route = url.substring(url.lastIndexOf('/') + 1);
  const history = useHistory();
  const { data: resources = [] } = useQueryResources(pir?.id);

  // To track PIR interactions
  const addClosedInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.RESOURCES,
      interactionType: interactionType.RESOURCES.CLOSED,
    });
  };

  const addViewedInteraction = () => {
    createInteraction({
      pir,
      dateTime: new Date(),
      moduleName: moduleName.RESOURCES,
      interactionType: interactionType.RESOURCES.VIEWED,
    });
  };

  const moduleInstruct = useMemo(
    () => (!isPir ? [t('resourceModuleCP.moduleCPInstruct')] : [t('resourceModulePIR.modulePIRInstruct')]),
    [isPir, t],
  );

  useEffect(() => {
    // check if viewing specific resource
    if (route !== MODAL_NAME && route !== 'home') {
      // viewing specific resource, route has resource id
      // find which resource to display based on resource id in route
      for (let i = 0; i < resources.length; i++) {
        if (resources[i].id === route) setSelectedResource(resources[i]);
        else setSelectedResource(null);
      }
    }
    // NO need to include window.location.href to dependency array because mutating them doesn't re-render the component
  }, [resources]);

  return (
    <Box>
      <ModuleContainer
        title={!isPir ? t('resourceModuleCP.moduleCPHeader') : t('resourceModulePIR.modulePIRHeader')}
        icon="spa"
        isEmptyModule={resources.length <= 0}
        modalName={MODAL_NAME}
        moduleInstruct={moduleInstruct}
        moduleButton={!isPir ? t('resourceModuleCP.moduleCPButton') : t('resourceModulePIR.modulePIRButton')}
        cogFunction={() => openModal(MODAL_NAME)}
      >
        <SimpleGrid
          spacing={2}
          p={3}
          bg="white"
          boxShadow="0px 3px 5px 0px rgba(0, 0, 0, 0.3)"
          borderRadius="8px"
          color="#8F8F8F"
        >
          <SimpleGrid
            columns={3}
            textTransform="uppercase"
            fontWeight="bold"
            paddingBottom={3}
            borderBottom="1px solid"
            fontSize={16}
            gridTemplateColumns="50px 78px auto"
            marginBottom="12px"
          >
            <Text />
            <Text>Type</Text>
            <Text>Title</Text>
          </SimpleGrid>
          <Accordion allowToggle={true}>
            {resources.map((resource, idx) => (
              <VerticalListResourceItem
                key={idx}
                resource={resource}
                name={resource.title}
                displayType={resource.resourceType}
              />
            ))}
          </Accordion>
        </SimpleGrid>
      </ModuleContainer>

      <Modal
        isOpen={selectedResource !== null}
        onClose={() => {
          history.push('/home');
          setSelectedResource(null);
          if (isPir) addViewedInteraction();
        }}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth={isPhone ? ['100%', '100%', '100%', '100%', '100%'] : ['100%', '98%', '90%', '85%', '75%']}
          p={[2, 2, 4, 8]}
          zIndex={1900}
        >
          <ModalHeader>{selectedResource?.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody w="50%">
            <List as="ol" mb={5}>
              <ListItem color="grey3.400" fontSize={16}>
                {!isPir ? t('resourceModuleCP.moduleTypeHeader') : t('resourceModulePIR.moduleTypeHeader')}
              </ListItem>
              <ListItem mb={3}>{`${selectedResource?.resourceType.charAt(0)}${selectedResource?.resourceType.slice(
                1,
              )}`}</ListItem>

              <ListItem color="grey3.400" fontSize={16}>
                {!isPir ? t('resourceModuleCP.moduleNoteHeader') : t('resourceModulePIR.moduleNoteHeader')}
              </ListItem>
              <ListItem mb={3}>{selectedResource?.note}</ListItem>

              <ListItem color="grey3.400" fontSize={16}>
                {!isPir ? t('resourceModuleCP.moduleMediaHeader') : t('resourceModulePIR.moduleMediaHeader')}
              </ListItem>

              {selectedResource?.link && (
                <ListItem>
                  <DisplayWebLink
                    href={selectedResource?.link}
                    linkText={
                      !isPir ? t('resourceModuleCP.moduleCPWebLink') : t('resourceModulePIR.moduleResourceLink')
                    }
                  />
                </ListItem>
              )}
              {selectedResource?.attachmentUrl && (
                <ListItem>
                  <DisplayWebLink
                    href={selectedResource?.attachmentUrl}
                    linkText={
                      !isPir
                        ? t('resourceModuleCP.moduleResourceAttachment')
                        : t('resourceModulePIR.moduleResourceAttachment')
                    }
                  />
                </ListItem>
              )}
            </List>
          </ModalBody>
        </ModalContent>
      </Modal>
      <ResourceModal
        isOpen={modal === MODAL_NAME && !selectedResource}
        onOpen={() => openModal(MODAL_NAME)}
        onClose={() => {
          closeModal();
          if (isPir) addClosedInteraction();
        }}
      />
    </Box>
  );
};

const MapStateToProps = (state: RootState) => {
  // Mapping required PIR states
  const { selectedLinkedUser } = state.linkedUsers;
  if (selectedLinkedUser === null || !selectedLinkedUser.pir) {
    throw new Error(
      'Selected linked user is null when linked user should already be selected when using this component.',
    );
  }

  return {
    pir: selectedLinkedUser.pir,
  };
};

const mapDispatchToProps = {
  // Actions for PIR
  createInteraction,
};

const connector = connect(MapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ResourceModule);
