import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, Skeleton, Link, Flex } from '@chakra-ui/react';
import ILinkedUser from '../../modules/linked-users/interfaces/ILinkedUser';
import IUser from '../../modules/user/interfaces/IUser';
import { getByRef as getUserByRef } from '../../modules/user/utils';
import FAIcon from '../../components/FAIcon';
import CPGeneralContactInfoBox from '../ManageUserInfoModal/CPGeneralContactInfoBox';

interface Props {
  networkMember: ILinkedUser;
  onDelete(): void;
  onEdit(user: IUser): void;
}

const ApprovedContact = ({ networkMember, onDelete, onEdit }: Props): JSX.Element => {
  const { t } = useTranslation('common');
  const [user, setUser] = useState<null | IUser>(null);
  const [loading, setLoading] = useState(true);
  const doNotNotify = 'notificationOnSOS' in networkMember && networkMember.notificationOnSOS === false ? true : false;

  useEffect(() => {
    setLoading(true);
    getUserByRef(networkMember.otherUser)
      .then((v) => {
        setUser(v);
        setLoading(false);
      })
      .catch((e) => {
        console.error(`Error getting user by ref: ${e}`);
      });
  }, [networkMember]);

  const edit = () => {
    if (user) {
      onEdit(user);
    }
  };

  return (
    <Box
      p={4}
      bg="grey6.50"
      border={doNotNotify ? '' : '1px solid'}
      borderColor={doNotNotify ? '' : 'purple3.600'}
      mb={4}
      data-test={user?.email}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <FAIcon
          icon={doNotNotify ? 'bell-slash' : 'bell'}
          mr={6}
          as="div"
          display="inline-block"
          fontSize={18}
          width={6}
          color="grey.500"
          mt="-2"
        />
        <Flex justifyContent="flex-end" fontSize={12}>
          {user && (
            <Link
              ml={2}
              textDecoration="underline"
              onClick={edit}
              _hover={{ textDecoration: 'none' }}
              data-test={user?.email + 'edit'}
            >
              {t('manageContactModal.editLink')}
            </Link>
          )}
          <Text mx={2}>{t('manageContactModal.approvedNotice')}</Text>
          <Link
            textDecoration="underline"
            onClick={onDelete}
            _hover={{ textDecoration: 'none' }}
            data-test={user?.email + 'delete'}
          >
            {t('manageContactModal.deleteLink')}
          </Link>
        </Flex>
      </Flex>
      <Skeleton isLoaded={!loading}>
        <Text fontWeight="bold" fontSize={14} overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
          {user && user.name} {networkMember.contactAlias && <span>({networkMember.contactAlias})</span>}
        </Text>
      </Skeleton>
      <Skeleton isLoaded={!loading}>
        <Text fontSize={14} overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
          {user && user.email}
        </Text>
      </Skeleton>
      <Skeleton isLoaded={!loading}>
        {user && user.phone && (
          <Text fontSize={14} overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
            {user.phone}
          </Text>
        )}
      </Skeleton>
      {networkMember.relationship && (
        <Skeleton isLoaded={!loading}>
          <Text fontSize={14} overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
            {networkMember.relationship}
          </Text>
        </Skeleton>
      )}
      {networkMember.preferredName && (
        <Skeleton isLoaded={!loading}>
          <Text fontSize={14} fontStyle="italic" overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
            {t('manageContactModal.preferredName')} {networkMember.preferredName}
          </Text>
        </Skeleton>
      )}
      {user?.cpGeneralContactInfo && (
        <Box>
          <Text fontSize={14} overflow="hidden" style={{ textOverflow: 'ellipsis' }}>
            {t('manageContactModal.cpOtherInfo')}
          </Text>
          <CPGeneralContactInfoBox loading={loading} cpGeneralContactInfo={user.cpGeneralContactInfo} />
        </Box>
      )}
    </Box>
  );
};

export default ApprovedContact;
