/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { initLoadEnabledGraphs, initLoadUser, loadInitialLink } from './actions';
import { store, RootState } from '../../redux/store';
import {
  BluetoothStatus,
  getWearableInfo,
  setupWearableLogging,
  uploadWearableData,
  wearableInitializeSDK,
  wearableSync,
  garminPluginInitializeSentry,
} from '../../lib/wearable';
import {
  garminSyncFail,
  garminUploadFail,
  garminSyncStart,
  garminSyncSuccess,
  garminSyncProgress,
  updateBluetoothStatus,
} from '../../redux/actions/garmin';
import { initializeBackgroundFetch } from 'src/lib/backgroundFetch';
import { useDevice } from 'src/DeviceContext';

interface SuppliedProps {
  children: JSX.Element | JSX.Element[];
}

interface GarminErrorEvent extends Event {
  detail?: {
    error?: string;
  };
}

type Props = SuppliedProps & PropsFromRedux;

const isCustomEvent = (event: Event): event is CustomEvent => 'detail' in event;

const GlobalLoader = (props: Props): JSX.Element => {
  const { initLoadUser, initLoadEnabledGraphs, loadInitialLink } = props;
  const { isMobileDevice, isCordova, isMobileDeviceAndCordova } = useDevice();

  const initWearable = async (): Promise<void> => {
    console.log('initWearable');
    if (isMobileDeviceAndCordova) {
      window.cordova.plugins.diagnostic.getBluetoothState(function (state: string) {
        const bluetoothStatus: BluetoothStatus = bluetoothStateToBluetoothStatus(state);
        store.dispatch(updateBluetoothStatus(bluetoothStatus));
      });
      window.cordova.plugins.diagnostic.registerBluetoothStateChangeHandler(function (state: string) {
        let bluetoothStatus: BluetoothStatus = bluetoothStateToBluetoothStatus(state);
        console.log('bluetoothStatus has changed and is:', bluetoothStatus);
        if (bluetoothStatus !== BluetoothStatus.BLUETOOTH_ON) {
          // make sure it's not just a temporary disconnection
          console.log('bluetooth status not on and about to wait 5 seconds and try again');
          setTimeout(() => {
            window.cordova.plugins.diagnostic.getBluetoothState(function (newState: string) {
              console.log('bluetoothStatus after a pause is:', newState);
              bluetoothStatus = bluetoothStateToBluetoothStatus(newState);
              store.dispatch(updateBluetoothStatus(bluetoothStatus));
            });
          }, 5000);
        } else {
          store.dispatch(updateBluetoothStatus(bluetoothStatus));
        }
      });
      // plugin reads sentry dsn from firebase config.
      try {
        await garminPluginInitializeSentry();
        console.log('initialized sentry in garmin plugin');
      } catch (err) {
        console.log('was unable to initialize sentry in garmin plugin');
      }

      // Garmin SDK reads license key from firebase config.
      try {
        await wearableInitializeSDK();
        console.log('initialized garmin SDK');
      } catch (err) {
        console.error('error initializing garmin SDK, will try again when user attempts to pair');
      }

      try {
        await initializeBackgroundFetch();
        console.log('initialized background fetch');
      } catch (err) {
        console.error('error initializing background fetch');
      }

      console.log('Attach other event handlers');
      window.addEventListener('garminsdk:syncstart', () => {
        console.log('garminsdk:syncstart');
        store.dispatch(garminSyncStart());
      });
      window.addEventListener('garminsdk:syncprogress', (e) => {
        if (isCustomEvent(e)) {
          console.log(`garminsdk:syncprogress ${e.detail.progress}`);
          store.dispatch(garminSyncProgress(e.detail.progress));
        }
      });
      window.addEventListener('garminsdk:syncerror', (event: GarminErrorEvent) => {
        const errorMessage = event.detail?.error || '';
        console.log('garminsdk:syncerror', errorMessage);
        store.dispatch(garminSyncFail(errorMessage));
      });
      window.addEventListener('garminsdk:synccomplete', () => {
        (async () => {
          console.log('garminsdk:synccomplete');
          store.dispatch(garminSyncSuccess());
          // upload data to firestore
          await uploadWearableData();
        })();
      });
      window.addEventListener('garminsdk:backgroundsynccomplete', () => {
        (async () => {
          console.log('garminsdk:backgroundsynccomplete');
          store.dispatch(garminSyncSuccess());
        })();
      });

      let hasWearableInfo = false;
      if (isCordova) {
        try {
          const wearableInfo = await getWearableInfo();
          hasWearableInfo = !!wearableInfo;
        } catch (err) {
          hasWearableInfo = false;
        }
      }

      // warmup garmin SDK and init redux state
      if (isCordova && hasWearableInfo) {
        // trigger wearable sync when device is ready
        console.log('Attach event handler on deviceconnect');
        window.addEventListener(
          'garminsdk:deviceconnect',
          () => {
            (async () => {
              console.log('garminsdk:deviceconnect');
              try {
                await setupWearableLogging();
                await wearableSync();
              } catch (err) {
                console.error('Error in setting up logging or wearable sync:', err);
              }
            })();
          },
          { once: true },
        );

        console.log('getting device from redux state');
        const garminDevice = store.getState().garmin;
        if (garminDevice !== null) {
          console.log('device in redux state');
          // having isSyncing or isUploading flag indicates that app was closed
          // before operation is completed. We have to restore correct device state
          if (garminDevice.isSyncing) {
            console.log('WAS SYNCING!');
            store.dispatch(garminSyncFail());
          } else if (garminDevice.isUploading) {
            console.log('WAS UPLOADING!');
            store.dispatch(garminUploadFail());
          }
        }
      }
    }
  };

  const bluetoothStateToBluetoothStatus = (state: string): BluetoothStatus => {
    const bluetoothStatus: BluetoothStatus =
      state === 'powered_on'
        ? BluetoothStatus.BLUETOOTH_ON
        : state === 'unauthorized'
        ? BluetoothStatus.UNAUTHORIZED
        : BluetoothStatus.BLUETOOTH_NOT_ON;
    return bluetoothStatus;
  };

  useEffect(() => {
    initLoadUser();

    const initRemoteConfig = async () => {
      try {
        await initLoadEnabledGraphs(isMobileDeviceAndCordova);
      } catch (err) {
        console.error('error loading enabled graphs', err);
      }
      try {
        await initWearable();
      } catch (err) {
        console.error('error in initWearable:', err);
      }
      try {
        await loadInitialLink(isMobileDeviceAndCordova);
      } catch (err) {
        console.error('error logging initial link:', err);
      }
    };

    initRemoteConfig();
    if (
      !isMobileDevice &&
      (window.location.href.includes('sign-up=true') || window.location.href.includes('verify=true'))
    ) {
      if (!window.location.href.includes('#')) {
        const oflUrl = new URL(window.location.href);

        const origin = oflUrl.origin;
        let path = oflUrl.pathname;
        const searchParams = oflUrl.search;

        const hashIndex = path.lastIndexOf('#');
        if (hashIndex !== -1) {
          path = path.substring(0, hashIndex);
        }
        const newUrl = `${origin}/#${path}${searchParams}`;
        window.location.href = newUrl;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.loading) {
    return (
      <Flex alignItems="center" justifyContent="center" h="100%">
        <Box w="100%" h="25%" textAlign="center">
          <Spinner />
          <Text mt={4} fontSize={24} fontWeight="bold">
            Initializing
          </Text>
        </Box>
      </Flex>
    );
  }

  return <>{props.children}</>;
};

const mapStateToProps = (state: RootState) => {
  const { init } = state;

  return {
    loading: init.userLoading || init.linkedAccountsLoading || init.initialLinkLoading,
  };
};

const mapDispatchToProps = {
  initLoadUser,
  initLoadEnabledGraphs,
  loadInitialLink,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GlobalLoader);
