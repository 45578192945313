import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import initReducer from '../modules/initialize/reducer';
import homeReducer from '../modules/home/reducer';
import userReducer from '../modules/user/reducer';
import authReducer from '../modules/auth/reducer';
import linkedUsersReducer from '../modules/linked-users/reducer';
import onboardingReducer from '../modules/onboarding/reducer';
import motivatorReducer from '../modules/motivator/reducer';
import { garminReducer } from './reducers/garmin';
import deviceDataReducer from '../modules/deviceData/reducer';
import accelerometerReducer from '../modules/accelerometer/reducer';
import deviceGarminReducer from '../modules/deviceGarmin/reducer';
import surveyReducer from '../modules/survey/reducer';
import vitalsReducer from './reducers/vitals';
import cravingReducer from '../modules/craving/reducer';
import aggregatesReducer from '../modules/summaryTable/reducer';
import todosReducer from '../modules/todo/reducer';
import adminReducer from './slices/admin';
import freeDateReducer from '../modules/freeDate/reducer';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import invitationReducer from '../modules/invitations/invitationReducer';

const rootReducer = combineReducers({
  //device: deviceReducer,
  accelerometer: accelerometerReducer,
  admin: adminReducer,
  auth: authReducer,
  craving: cravingReducer,
  deviceData: deviceDataReducer,
  deviceGarmin: deviceGarminReducer,
  freeDate: freeDateReducer,
  garmin: garminReducer,
  home: homeReducer,
  init: initReducer,
  linkedUsers: linkedUsersReducer,
  motivator: motivatorReducer,
  onboarding: onboardingReducer,
  aggregates: aggregatesReducer,
  survey: surveyReducer,
  todo: todosReducer,
  user: userReducer,
  userInvitations: invitationReducer,
  vitals: vitalsReducer,
});

const persistConfig = {
  key: 'root',
  whitelist: ['garmin'],
  stateReconciler: autoMergeLevel2,
  storage,
};
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

export type RootState = ReturnType<typeof rootReducer>;
export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);
