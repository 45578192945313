import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { Box, Flex, Heading, Text, useToast, Progress } from '@chakra-ui/react';
import PrimaryButton from '../../components/PrimaryButton';
import { SecondaryButton } from '../../components/SecondaryButton';
import PageHeading from '../../components/PageHeading';
import { createDeviceGarmin, deregisterGarminAPI, updateDeviceGarmin } from '../deviceGarmin/actions';
import IDeviceGarmin from '../deviceGarmin/interfaces/IDeviceGarmin';
import { RootState } from '../../redux/store';
import { createInteraction } from '../interactions/actions';
import { moduleName, interactionType } from '../interactions/constants';
import NarrowInterior from '../../components/NarrowInterior';
import SelectLinkedUser from '../linked-users/SelectLinkedUser';
import {
  wearablePair,
  wearableForget,
  getWearableLogging,
  getWearableInfo,
  getWearableBatteryStatus,
  setupWearableLogging,
  BluetoothStatus,
  requestBluetoothAuth,
  checkBluetoothAuthStatus,
  BluetoothAuthState,
  isThisAndroid,
  wearableSync,
} from '../../lib/wearable';
// import { geolocationStatusOn, geolocationStatusOff } from '../../redux/actions/garmin';
import FAIcon from '../../components/FAIcon';
import { UserRole } from '../user/interfaces/IUser';
import { IPirLinkedUser } from '../linked-users/interfaces/ILinkedUser';
import { selectLinkedUser } from '../linked-users/actions';
import PrimaryError from 'src/components/PrimaryError';
import PairAlertDialog from './PairAlertDialog';
import { bluetoothAuthCheckNeeded, stepsToConnect } from './deviceHelpers';
import { useDevice } from 'src/DeviceContext';

export enum ConnectType {
  SYNC = 'SYNC',
  PAIR = 'PAIR',
}

const DevicePage = ({
  selectLinkedUser,
  selectedLinkedUser,
  // geolocationEnabled,
  createInteraction,
  createDeviceGarmin,
  userRole,
  pirLinkedUser,
}: PropsFromRedux): React.ReactElement | null => {
  const { t } = useTranslation('device');
  const toast = useToast();
  const { isCordova } = useDevice();
  // const dispatch = useDispatch();
  const [isPairing, setIsPairing] = useState(false);
  const isAndroid = isThisAndroid();
  const [needsToAuthorizeBluetooth, setNeedsToAuthorizeBluetooth] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const garminDevice = useSelector((state: RootState) => state.garmin);
  const garminDeviceAPI = useSelector((state: RootState): IDeviceGarmin | null => state.deviceGarmin.deviceGarmin);
  const bluetoothStatus = garminDevice.bluetoothStatus;
  const [devicePairedAndBluetoothOff, setDevicePairedAndBluetoothOff] = useState(
    garminDevice.name &&
      (bluetoothStatus === BluetoothStatus.BLUETOOTH_NOT_ON || bluetoothStatus === BluetoothStatus.UNAUTHORIZED),
  );

  useEffect(() => {
    console.log('garminDevice.bluetoothStatus changed, and is', garminDevice.bluetoothStatus, bluetoothStatus);
    const pairedAndBluetoothOff =
      garminDevice.name &&
      (garminDevice.bluetoothStatus === BluetoothStatus.BLUETOOTH_NOT_ON ||
        garminDevice.bluetoothStatus === BluetoothStatus.UNAUTHORIZED);
    setDevicePairedAndBluetoothOff(pairedAndBluetoothOff);
  }, [garminDevice.bluetoothStatus, garminDevice.name]);

  const beforePair = async () => {
    try {
      if (bluetoothStatus === BluetoothStatus.BLUETOOTH_ON) {
        await toastWearablePair();
      } else if (bluetoothStatus === BluetoothStatus.UNAUTHORIZED) {
        const authStatus = await checkBluetoothAuthStatus();
        if (authStatus === BluetoothAuthState.NOT_REQUESTED) {
          await requestBluetoothAuth();
        } else {
          setNeedsToAuthorizeBluetooth(true);
          setAlertDialogOpen(true);
        }
      } else {
        setAlertDialogOpen(true);
      }
    } catch (err) {
      console.error('Bluetooth status error:', err);
      let displayError: string | null = typeof err === 'string' ? err : JSON.stringify(err);
      if (isAndroid && err === 'Device scan failed with error code 3') {
        const bluetoothAuthWasNeeded = await bluetoothAuthCheckNeeded();
        if (bluetoothAuthWasNeeded) {
          await toastWearablePair();
          displayError = null;
        }
      }
      if (displayError) {
        toast({
          title: 'Bluetooth status error',
          description: displayError,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      }
    }
  };

  const toastWearablePair = async () => {
    setIsPairing(true);
    try {
      await wearablePair();
      setIsPairing(false);
      try {
        await getWearableInfo();
        await getWearableBatteryStatus();
        await setupWearableLogging();
      } catch (error) {
        setIsPairing(false);
        let postPairError = typeof error === 'string' ? error : JSON.stringify(error);

        if (postPairError.toLowerCase() === 'no device found') {
          postPairError = t('deviceModal.noDeviceFound');
        }
        console.error('Post-pairing error:', typeof postPairError, postPairError);
        toast({
          title: 'Setup error',
          description: postPairError,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      }
    } catch (pairError) {
      setIsPairing(false);
      console.error('Wearable pair error:', typeof pairError, pairError);
      if (isAndroid && pairError == 'Device scan failed with error code 3') {
        throw pairError;
      } else {
        let errorText = typeof pairError === 'string' ? pairError : JSON.stringify(pairError);

        if (errorText.toLowerCase() === 'no device found') {
          console.log('i am actually changing this here');
          errorText = t('deviceModal.noDeviceFound');
        } else {
          console.log('i guess not', errorText.toLowerCase(), 'no device found');
        }
        toast({
          title: 'Pair error',
          description: errorText,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      }
    } finally {
      setIsPairing(false);
    }
  };

  const toastWearableSync = async () => {
    try {
      await wearableSync();
    } catch (err: unknown) {
      console.error('Wearable sync error:', err);
      let errorText = typeof err === 'string' ? err : JSON.stringify(err);
      if (errorText.toLowerCase() === 'no device found') {
        errorText = t('deviceModal.noDeviceFound');
      } else if (errorText.toLowerCase() === 'device not connected') {
        errorText = t('deviceModal.deviceNotConnected');
      }
      toast({
        title: 'Sync error',
        description: errorText,
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  // const geoOn = function () {
  //   dispatch(geolocationStatusOn());
  // };
  // const geoOff = function () {
  //   dispatch(geolocationStatusOff());
  // };

  const isSyncingOrUploading = garminDevice.isSyncing || garminDevice.isUploading;
  const syncAndUploadProgress = ((garminDevice.syncProgress || 0) * 3) / 4 + (garminDevice.uploadProgress || 0) / 4;

  useEffect(() => {
    if (garminDevice.name) {
      getWearableBatteryStatus();
    }
  }, []);

  // const addModifiedFieldInteraction = () => {
  //   createInteraction({
  //     pir: selectedLinkedUser?.pir || null,
  //     dateTime: new Date(),
  //     moduleName: moduleName.DEVICE,
  //     interactionType: interactionType.DEVICE.MODIFIED_FIELD,
  //   });
  // };

  useEffect(() => {
    const createInteractionForUser = async () => {
      if (
        (userRole === UserRole.CP && !selectedLinkedUser?.pir) ||
        (userRole === UserRole.USER && !pirLinkedUser) ||
        !isCordova
      ) {
        return;
      }

      if (!selectedLinkedUser?.pir) {
        await selectLinkedUser(pirLinkedUser);
      }

      createInteraction({
        pir: selectedLinkedUser?.pir || (pirLinkedUser as IPirLinkedUser).pir,
        dateTime: new Date(),
        moduleName: moduleName.DEVICE,
        interactionType: interactionType.DEVICE.OPEN_MODAL,
      });
    };
    createInteractionForUser();
  }, [selectedLinkedUser]);

  if (userRole && userRole !== UserRole.USER && selectedLinkedUser === null) {
    return (
      <NarrowInterior>
        <PageHeading mb={8}>Please select a patient to continue:</PageHeading>
        <SelectLinkedUser />
      </NarrowInterior>
    );
  }

  if (selectedLinkedUser === null) {
    return null;
  }

  const { pir } = selectedLinkedUser;

  const data = null;

  if (pir === null || pir === undefined) {
    return null;
  }

  // for Garmin API Auth flow
  const url = process.env.REACT_APP_GARMIN_API_ENDPOINT;
  const redirectUriGarmin = process.env.REACT_APP_GARMIN_API_REDIRECT;
  const openInAppBrowser = () => {
    const browser = window.cordova.InAppBrowser.open(url, '_blank', 'location=yes');
    browser.addEventListener('loadstart', (event: any) => {
      if (event.url.indexOf(redirectUriGarmin) === 0) {
        const match = event.url.match(/\?.*/);
        if (match && match.length > 0) {
          const query = new URLSearchParams(match[0]);
          const userId = query.get('garminId');

          if (!userId) {
            return;
          }

          if (garminDeviceAPI?.userId && garminDeviceAPI?.status === 'AUTHORIZED') {
            return;
          }

          createDeviceGarmin({
            pir,
            userId,
            status: 'AUTHORIZED',
          });
        }

        browser.close();
      }
    });
    browser.addEventListener('loaderror', (err: any) => {
      console.error(err);
    });
  };

  const controlsGarminAPI = (
    <>
      {garminDeviceAPI && garminDeviceAPI.status === 'AUTHORIZED' ? (
        <PrimaryButton onClick={() => deregisterGarminAPI(garminDeviceAPI)} mt={3} mb={3} alignItems="center">
          {/* {t('deviceModal.syncButton')} */}
          Disconnect Garmin&#8482; API
        </PrimaryButton>
      ) : (
        <PrimaryButton onClick={openInAppBrowser} mt={3} mb={3} alignItems="center">
          {/* {t('deviceModal.syncButton')} */}
          Connect Garmin&#8482; API
        </PrimaryButton>
      )}
    </>
  );

  return (
    <Box p={6} w="100%">
      <Flex flexDirection="column" alignItems="center">
        {data}
      </Flex>

      {/* this needs to be conditional on whether or not we are in cordova */}
      {/* {isCordova && (
        <> */}
      {/* <Flex pb={4} flexDirection="column">
            <PageHeading mb={4} mt={2}>
              Device Data
            </PageHeading>
            <Text fontSize={14}>
              Recovery uses your location and motion detection to help determine when and how you may need support.
            </Text>
          </Flex>
          <hr /> */}
      {/* <Flex py={4}>
            <Heading as="h4" fontSize={18} fontWeight="normal">
              Location Usage
            </Heading>
            <Spacer />
            <Switch
              size="lg"
              color="purple3.600"
              isChecked={geolocationEnabled}
              onChange={() => {
                !geolocationEnabled ? geoOn() : geoOff();
                addModifiedFieldInteraction();
              }}
            />
          </Flex> */}
      {/* </>
      )} */}
      <Flex direction="column" align="start">
        <Heading as="h3" fontSize={20} mb={4} mt={2}>
          {garminDevice.name === undefined ? t('deviceModal.headerPair') : t('deviceModal.headerSync')}
        </Heading>

        {garminDevice.name === undefined ? (
          <Flex alignItems="left" direction="column" width="100%">
            {stepsToConnect(ConnectType.PAIR, bluetoothStatus, t, false)}
            <br />
            <Flex direction="column">
              <PrimaryButton width="100%" onClick={beforePair} my={3} alignItems="center" disabled={isPairing}>
                &nbsp;{t('deviceModal.pairButton')}&nbsp;{isPairing && ' '}
                {isPairing && <FAIcon icon="sync-alt" className={!garminDevice.name && isPairing ? 'fa-spin' : ''} />}
              </PrimaryButton>
              <br />
              {controlsGarminAPI}
            </Flex>
          </Flex>
        ) : (
          <Flex width="100%" direction="column" alignItems="left">
            <Flex direction="column">
              <Text fontSize={22} mb={5}>
                <b>{t('deviceModal.pairedName')}</b> {garminDevice.name}
              </Text>
              <Text mb={5} onDoubleClick={getWearableLogging}>
                <b>{t('deviceModal.pairedBattery')}</b> {garminDevice.battery > -1 ? garminDevice.battery + '%' : '--'}
              </Text>
              <Text mb={5}>
                <b>{t('deviceModal.pairedSync')}</b>{' '}
                {garminDevice.lastUploadCompleted
                  ? new Date(garminDevice.lastUploadCompleted).toLocaleString()
                  : 'a while ago'}
              </Text>
              <Text mb={2}>
                <b>{t('deviceModal.syncFails') + ': '}</b> {garminDevice.syncErrors}
              </Text>
              {garminDevice.syncErrorDetails && (
                <Text mb={2}>
                  <b>{t('deviceModal.syncErrorDetails') + ': '}</b> {garminDevice.syncErrorDetails}
                </Text>
              )}
            </Flex>

            {!devicePairedAndBluetoothOff ? (
              <br />
            ) : (
              <Flex alignItems="left" direction="column">
                <PrimaryError width="100%">
                  {isAndroid
                    ? t('deviceModal.bluetoothOffSyncErrorAndroid')
                    : bluetoothStatus === BluetoothStatus.UNAUTHORIZED
                    ? t('deviceModal.bluetoothUnauthorizedSyncError')
                    : t('deviceModal.bluetoothOffSyncErrorIos')}
                </PrimaryError>
                {stepsToConnect(ConnectType.SYNC, bluetoothStatus, t, false)}
              </Flex>
            )}
            <Flex alignItems="left" direction="column" width="100%">
              <PrimaryButton
                onClick={toastWearableSync}
                my={3}
                alignItems="center"
                disabled={isSyncingOrUploading || !!devicePairedAndBluetoothOff}
                width="100%"
              >
                {t(`deviceModal.${isSyncingOrUploading ? 'syncButtonProgress' : 'syncButton'}`)}&nbsp;
                <FAIcon icon="sync-alt" className={isSyncingOrUploading ? 'fa-spin' : ''} />
              </PrimaryButton>
              {isSyncingOrUploading && <Progress height="2px" value={syncAndUploadProgress} />}
              <SecondaryButton
                width="100%"
                onClick={wearableForget}
                my={3}
                alignItems="center"
                disabled={isSyncingOrUploading}
              >
                {t('deviceModal.unpairButton')}
              </SecondaryButton>
              <br />
              {controlsGarminAPI}
            </Flex>
          </Flex>
        )}
      </Flex>

      {/* Alert user if bluetooth isn't enabled */}
      <PairAlertDialog
        isOpen={alertDialogOpen}
        setAlertDialogOpen={setAlertDialogOpen}
        needsToAuthorizeBluetooth={needsToAuthorizeBluetooth}
      />
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  const { selectedLinkedUser } = state.linkedUsers;
  const enabled = state.garmin.geolocationStatus;
  const userRole = state.user?.user?.role;

  return {
    selectedLinkedUser,
    geolocationEnabled: enabled,
    userRole,
    pirLinkedUser: state.linkedUsers.pir,
  };
};

const mapDispatchToProps = {
  createInteraction,
  createDeviceGarmin,
  updateDeviceGarmin,
  selectLinkedUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DevicePage);
