import firebase from 'firebase/compat/app';
import { ToDoActions, ToDoActionTypes } from './actions';
import IToDo from './interfaces/IToDo';

interface ToDoState {
  loading: boolean;
  creating: boolean;
  todos: null | IToDo[];
  selectedToDo: null | IToDo;
  unsubFn: null | firebase.Unsubscribe;
}

const defaultState: ToDoState = {
  loading: false,
  creating: false,
  todos: null,
  selectedToDo: null,
  unsubFn: null,
};

const todosReducer = (state: ToDoState = defaultState, action: ToDoActions): ToDoState => {
  switch (action.type) {
    case ToDoActionTypes.SET_TODOS:
      return {
        ...state,
        todos: action.todos,
      };

    case ToDoActionTypes.GETTING_TODOS:
      return {
        ...state,
        loading: true,
      };

    case ToDoActionTypes.GOT_TODOS:
      return {
        ...state,
        loading: false,
      };

    case ToDoActionTypes.SET_TODOS_UNSUB:
      return {
        ...state,
        unsubFn: action.unsubFn,
      };

    case ToDoActionTypes.CREATING_TODO:
      return {
        ...state,
        creating: true,
      };

    case ToDoActionTypes.CREATED_TODO:
      return {
        ...state,
        creating: false,
      };

    case ToDoActionTypes.UPDATING_TODO:
      return {
        ...state,
        loading: true,
      };

    case ToDoActionTypes.UPDATED_TODO:
      return {
        ...state,
        todos: action.todos,
        loading: false,
      };

    case ToDoActionTypes.SET_SELECTED_TODO:
      return {
        ...state,
        selectedToDo: action.selectedToDo,
      };

    case ToDoActionTypes.CLEAR_SELECTED_TODO:
      return {
        ...state,
        selectedToDo: null,
      };

    default:
      return state;
  }
};

export default todosReducer;
