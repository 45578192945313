import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Flex, Tabs, TabList, TabPanels, TabPanel, Tab } from '@chakra-ui/react';
import { RootState } from '../../redux/store';
import SupportNetworkModule from './SupportNetworkModule/SupportNetworkModule';
import ResourceModule from '../resources/ResourceModule';
import MeditationModule from '../meditations/MeditationModule';
import MotivatorModule from '../motivator/MotivatorModule';
import MedicationModule from '../medications/MedicationModule';
import FreeDateModule from '../freeDate/FreeDateModule';
import TodoModule from '../todo/TodoModule';
import SurveyModule from '../survey/SurveyModule';
import AppointmentModule from '../appointments/AppointmentModule';
import Slider from '../../components/Slider';
import { AppointmentsContext } from '../interior/Index';
import { useModal } from 'src/ModalContext';

const PirDashboard = (): JSX.Element => {
  const { setRefresh } = useContext(AppointmentsContext);
  const [activeTab, setActiveTab] = useState(0);
  const { appointmentTodos, meditationTodos } = useModal();

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    if (index === 2) {
      setRefresh(true);
    }
  };

  return (
    <Flex h="100%" direction="column">
      <Tabs
        size="sm"
        maxW="1200px"
        isFitted={true}
        flex="1"
        overflow="auto"
        display="flex"
        flexDirection="column"
        onChange={handleTabChange}
      >
        <TabList w="100%" bg="white">
          {['For You', 'Tools', 'Treatment'].map((tabTitle, index) => (
            <Tab
              key={index}
              _selected={{
                fontWeight: 'bold',
                color: 'purple3.600',
                borderColor: 'purple3.600',
                borderBottom: '2px solid purple3.600',
                borderRadius: '2px',
              }}
              data-test={'pir-dashboard-tab-' + index}
            >
              {tabTitle}
            </Tab>
          ))}
        </TabList>
        <TabPanels flex="1" overflow="auto">
          <TabPanel>
            <Flex flexDirection={['column', 'column', 'row']} flexBasis="100%">
              <Flex minWidth="0px" flexDirection="column" flexBasis={['100%', '100%', '50%']} pr={[0, 0, 3]}>
                <Slider>
                  <MotivatorModule />
                  <FreeDateModule />
                  <SurveyModule />
                </Slider>
              </Flex>
              <Flex minWidth="0px" flexDirection="column" flexBasis={['100%', '100%', '50%']} pl={[0, 0, 3]}>
                <Box mb={5}>
                  <SupportNetworkModule />
                </Box>
              </Flex>
            </Flex>
            <Box flexBasis={['100%', '100%']} mb={5}>
              <TodoModule isCP={false} />
            </Box>
          </TabPanel>

          <TabPanel>
            <Flex alignItems="stretch" flexDirection={['column', 'column', 'column']} flexBasis="100%">
              {activeTab == 1 && (
                <Flex minWidth="0px" flexDirection="column" flexBasis={['100%', '100%', '100%']} pr={[0, 0, 0]}>
                  <Box mb={5} w="100%">
                    <ResourceModule isPir={true} />
                  </Box>
                </Flex>
              )}
              {(activeTab == 1 || meditationTodos) && (
                <Flex minWidth="0px" flexDirection="column" flexBasis={['100%', '100%', '100%']} pl={[0, 0, 0]}>
                  <Box mb={5}>
                    <MeditationModule isPir={true} />
                  </Box>
                </Flex>
              )}
            </Flex>
          </TabPanel>

          <TabPanel>
            {(activeTab === 2 || appointmentTodos) && (
              <Box flexBasis={['100%', '100%']} mb={5}>
                <AppointmentModule isPir={true} />
              </Box>
            )}

            <Box flexBasis={['100%', '100%']} mb={5}>
              <MedicationModule />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    invites: state.linkedUsers.invites ?? [],
  };
};

const connector = connect(mapStateToProps);

export default connector(PirDashboard);
