import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

interface ModalContextProps {
  modal: string | null;
  openModal: (modalName: string) => void;
  closeModal: () => void;
  setMeditationTodos: React.Dispatch<React.SetStateAction<boolean>>;
  meditationTodos: boolean;
  setAppointmentTodos: React.Dispatch<React.SetStateAction<boolean>>;
  appointmentTodos: boolean;
}

const ModalContext = createContext<ModalContextProps>({
  modal: null,
  openModal: () => {
    // do nothing
  },
  closeModal: () => {
    // do nothing
  },
  setAppointmentTodos: () => {
    // do nothing
  },
  setMeditationTodos: () => {
    // do nothing
  },
  meditationTodos: false,
  appointmentTodos: false,
});

export const ModalProvider: React.FC = ({ children }) => {
  const [modal, setModal] = useState<string | null>(null);
  const history = useHistory();
  const [appointmentTodos, setAppointmentTodos] = useState(false);
  const [meditationTodos, setMeditationTodos] = useState(false);

  const openModal = useCallback((modalName: string) => {
    setModal(modalName);
  }, []);

  const closeModal = useCallback(() => {
    setModal(null);
    history.push('/home');
  }, []);

  const value = useMemo(() => ({ modal, openModal, closeModal, meditationTodos, setMeditationTodos, appointmentTodos, setAppointmentTodos }), [modal, openModal, closeModal]);

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export const useModal = () => useContext(ModalContext);
