import { Action } from 'redux';
import { BluetoothStatus } from 'src/lib/wearable';

export enum GarminActionTypes {
  SET_GARMIN = 'SET_GARMIN',
  UPDATE_BATTERY = 'UPDATE_BATTERY',
  CLEAR_GARMIN = 'CLEAR_GARMIN',
  LOGOUT = 'LOGOUT',
  GARMIN_BACKGROUND_SYNC_TIMEOUT = 'GARMIN_BACKGROUND_SYNC_TIMEOUT',
  GARMIN_SYNC_START = 'GARMIN_SYNC_START',
  GARMIN_SYNC_PROGRESS = 'GARMIN_SYNC_PROGRESS',
  GARMIN_SYNC_FAIL = 'GARMIN_SYNC_FAIL',
  GARMIN_SYNC_SUCCESS = 'GARMIN_SYNC_SUCCESS',
  GARMIN_UPLOAD_START = 'GARMIN_UPLOAD_START',
  GARMIN_UPLOAD_PROGRESS = 'GARMIN_UPLOAD_PROGRESS',
  GARMIN_UPLOAD_FAIL = 'GARMIN_UPLOAD_FAIL',
  GARMIN_UPLOAD_SUCCESS = 'GARMIN_UPLOAD_SUCCESS',
  GARMIN_DB_STATUS = 'GARMIN_DB_STATUS',
  GEOLOCATION_STATUS_ON = 'GEOLOCATION_STATUS_ON',
  GEOLOCATION_STATUS_OFF = 'GEOLOCATION_STATUS_OFF',
  UPDATE_BLUETOOTH_STATUS = 'UPDATE_BLUETOOTH_STATUS',
}

export type GarminActions =
  | SetGarminAction
  | UpdateBatteryAction
  | ClearGarminAction
  | LogoutGarminAction
  | GarminBackgroundSyncTimeoutAction
  | GarminSyncStartAction
  | GarminSyncProgressAction
  | GarminSyncFailAction
  | GarminSyncSuccessAction
  | UpdateBluetoothStatusAction
  | GarminUploadStartAction
  | GarminUploadProgressAction
  | GarminUploadFailAction
  | GarminUploadSuccessAction
  | GarminDBStatusAction
  | GeolocationStatusOnAction
  | GeolocationStatusOffAction;

export interface SetGarminAction extends Action<GarminActionTypes.SET_GARMIN> {
  name: string | undefined;
  battery: number;
  dataset: number[];
  hourOffset: boolean;
}
export const setGarmin = (name: string | undefined): SetGarminAction => {
  let hourOffset = false;
  let dataset = [1, 2, 3, 4, 5, 6, 7, 8];
  if (name !== undefined && name.search('active') > -1) {
    hourOffset = true;
    dataset = [1, 2, 3, 4, 5];
  }
  return {
    type: GarminActionTypes.SET_GARMIN,
    name,
    dataset,
    hourOffset,
    battery: -1,
  };
};

export interface UpdateBatteryAction extends Action<GarminActionTypes.UPDATE_BATTERY> {
  battery: number;
}
export const updateBattery = (battery: number): UpdateBatteryAction => ({
  type: GarminActionTypes.UPDATE_BATTERY,
  battery,
});

export type ClearGarminAction = Action<GarminActionTypes.CLEAR_GARMIN>;
export const clearGarmin = (): ClearGarminAction => {
  return {
    type: GarminActionTypes.CLEAR_GARMIN,
  };
};

export type LogoutGarminAction = Action<GarminActionTypes.LOGOUT>;
export const logoutGarmin = (): LogoutGarminAction => ({
  type: GarminActionTypes.LOGOUT,
});

export type GarminBackgroundSyncTimeoutAction = Action<GarminActionTypes.GARMIN_BACKGROUND_SYNC_TIMEOUT>;
export const garminBackgroundSyncTimeout = (): GarminBackgroundSyncTimeoutAction => ({
  type: GarminActionTypes.GARMIN_BACKGROUND_SYNC_TIMEOUT,
});

export type GarminSyncStartAction = Action<GarminActionTypes.GARMIN_SYNC_START>;
export const garminSyncStart = (): GarminSyncStartAction => ({
  type: GarminActionTypes.GARMIN_SYNC_START,
});

export interface GarminSyncProgressAction extends Action<GarminActionTypes.GARMIN_SYNC_PROGRESS> {
  progress: number;
}
export const garminSyncProgress = (progress: number): GarminSyncProgressAction => ({
  type: GarminActionTypes.GARMIN_SYNC_PROGRESS,
  progress,
});

export interface GarminSyncFailAction extends Action<GarminActionTypes.GARMIN_SYNC_FAIL> {
  errorDetail: string;
}

export const garminSyncFail = (detail?: string): GarminSyncFailAction => ({
  type: GarminActionTypes.GARMIN_SYNC_FAIL,
  errorDetail: detail ?? '',
});

export type GarminSyncSuccessAction = Action<GarminActionTypes.GARMIN_SYNC_SUCCESS>;
export const garminSyncSuccess = (): GarminSyncSuccessAction => ({
  type: GarminActionTypes.GARMIN_SYNC_SUCCESS,
});

export interface UpdateBluetoothStatusAction extends Action<GarminActionTypes.UPDATE_BLUETOOTH_STATUS> {
  status: BluetoothStatus | undefined;
}
export const updateBluetoothStatus = (status: BluetoothStatus | undefined): UpdateBluetoothStatusAction => ({
  type: GarminActionTypes.UPDATE_BLUETOOTH_STATUS,
  status,
});

export type GarminUploadStartAction = Action<GarminActionTypes.GARMIN_UPLOAD_START>;
export const garminUploadStart = (): GarminUploadStartAction => ({
  type: GarminActionTypes.GARMIN_UPLOAD_START,
});

export interface GarminUploadProgressAction extends Action<GarminActionTypes.GARMIN_UPLOAD_PROGRESS> {
  progress: number;
  lastChunkCompleted: string;
}
export const garminUploadProgress = (progress: number, lastChunkCompleted: string): GarminUploadProgressAction => ({
  type: GarminActionTypes.GARMIN_UPLOAD_PROGRESS,
  progress,
  lastChunkCompleted,
});

export interface GarminUploadFailAction extends Action<GarminActionTypes.GARMIN_UPLOAD_FAIL> {
  errorDetail: string;
}
export const garminUploadFail = (detail?: string): GarminUploadFailAction => ({
  type: GarminActionTypes.GARMIN_UPLOAD_FAIL,
  errorDetail: detail ?? '',
});

export type GarminUploadSuccessAction = Action<GarminActionTypes.GARMIN_UPLOAD_SUCCESS>;
export const garminUploadSuccess = (): GarminUploadSuccessAction => ({
  type: GarminActionTypes.GARMIN_UPLOAD_SUCCESS,
});

export interface GarminDBStatusAction extends Action<GarminActionTypes.GARMIN_DB_STATUS> {
  id: string | undefined;
  pir: string | undefined;
}
export const garminDBStatus = (id: string | undefined, pir: string | undefined): GarminDBStatusAction => ({
  type: GarminActionTypes.GARMIN_DB_STATUS,
  id,
  pir,
});

export type GeolocationStatusOnAction = Action<GarminActionTypes.GEOLOCATION_STATUS_ON>;
export const geolocationStatusOn = (): GeolocationStatusOnAction => ({
  type: GarminActionTypes.GEOLOCATION_STATUS_ON,
});

export type GeolocationStatusOffAction = Action<GarminActionTypes.GEOLOCATION_STATUS_OFF>;
export const geolocationStatusOff = (): GeolocationStatusOffAction => ({
  type: GarminActionTypes.GEOLOCATION_STATUS_OFF,
});
